<div class="container-fluid row no-gutters" *ngIf="type == 'building_sid' && nameSid != ''">
	<div class="col-md-1 imagenes">
		<img  class="m-5" src="https://munily-public-cdn.s3.amazonaws.com/public/dashboard/munily.png">
	</div>
	<div class="section-1 col-12 col-md-5 px-0">
		<img class="img-fluid" src="https://munily-public-cdn.s3.amazonaws.com/public/dashboard/Group+10422.png">
	</div>
	<div class="section-2 col-12 col-md-6 px-0">
		<div class="row mt-md-5 ml-5">
			<div class="col-12 col-md-6" style="font-family: 'SF Compact Display'; color: #454545">
				<p style="font-weight: 700; font-size: 20px; line-height: 29,83px; text-align: justify; font-style: normal;">
					Para poder ingresar al <strong>{{ nameSid }}</strong> debe <strong style="font-weight: 520;">abrir este enlace desde un teléfono inteligente</strong>.
					Ya que Munily es una app movil.
				</p>
				<p style="font-weight: 700; font-size: 20px; line-height: 29,83px; text-align: justify; font-style: normal;">
					También puedes ingresar <strong style="font-weight: 520;">copiando el código de 4 dígitos del <strong>{{ nameSid }}</strong> manualmente</strong>
				</p>
				<p style="font-family: 'Sfcompact'; font-weight: 900; font-size: 25px; line-height: 29,83px; text-align: justify; color: #308be3;">
					Código de 4 dígitos: <strong style="font-size: 28px;">{{ token }}</strong>
				</p>
			</div>
		</div>
	</div>
</div>
