import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit} from '@angular/core';
import { PublicService } from "../../services/public.service";
import { ActivatedRoute, Router } from "@angular/router"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-download-app',
  templateUrl: './modal-download-app.component.html',
  styleUrls: ['./modal-download-app.component.scss']
})
export class ModalDownloadAppComponent implements OnInit, AfterViewInit {
@ViewChild('contentDownload') templateRef: TemplateRef<any>;
text : any;
icon : string = "";
status : string = "";
unitId : string = "";
dynamicLink : string = "";
user_id : any = null;
invitation_id : any = null;
emoji:any;
success:boolean = true;

 constructor(
  private modalService: NgbModal,
  private publicService : PublicService,
  private route : ActivatedRoute,
  private router: Router) {

	this.route.queryParams.subscribe((params:any)=>{
		this.user_id = params.user_id ? params.user_id : this.user_id;
		this.invitation_id = params.invitation_id ? params.invitation_id : this.invitation_id;
		this.status = params.status ? params.status : this.status,
 		this.unitId = params.unit_id ? params.unit_id : this.unitId;
 		this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
	});
 }

 ngOnInit() {
 	this.getStatus();
 }

 ngAfterViewInit(){
 	this.openDownload(this.templateRef);
 }

 openDownload(content) { //Abre la Modal
 	this.modalService.open(content, { windowClass: 'download-app-modal', size: 'sm', backdrop:"static", keyboard: false }).result.then((result) => {
 	}, (reason) => {});
 	setTimeout(()=> {
 		let modal = <HTMLElement> document.querySelector(".download-app-modal .modal-sm");
 		modal.style.height = "-webkit-fill-available";
 		modal.style.display = "flex";
 		modal.style.alignItems = "center";
 	},1)
 	return false;
 }

 getStatus(){
 	let data = {
 		userId : this.user_id,
 		status : this.status,
 		unitId : this.unitId
 	};

 	this.publicService.invitationResponse(data , this.invitation_id).subscribe((resp:any)=>{
 		resp = resp.data;
 		this.dynamicLink = resp.dynamicLink ? resp.dynamicLink : "";
 		if (resp.success) {
 			this.emoji = null;
 			this.success = true;
 			switch (resp.invitation_final_status) {
 				case "approved":
 					this.text = "¡Tu visita ha sido aprobada con éxito!";
 					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-succes.svg";
 				break;
 				case "denied":
 					this.text = "Tu visita ha sido denegada";
 					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-wrong.svg";
 				break;
 			}

 		}else{

 			switch (resp.invitation_final_status) {
 				case "approved":
 				case "concreted":
 					this.text = ["Hm...","Parece que alguien en tu \n hogar ya aprobó la visita."]
 					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-exclamation.svg";
 					this.emoji= "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/Hmm.png";
 					this.success = false;
 				break;
 				case "denied":
 					this.text = ["Hm...","Parece que alguien en tu \n hogar rechazó la visita."];
 					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-exclamation.svg";
 					this.emoji= "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/Hmm.png";
 					this.success = false;
 				break;
 				case "canceled":
 					this.text = ["¡Oh rayos!","El vigilante ha cancelado \n la visita."];
 					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-exclamation.svg";
 					this.emoji= "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/frezee.png";
 					this.success = false;
 				break;
 				default:
	 				this.text = ["¡Oh rayos!","La visita ya expiró"];
					this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-exclamation.svg";
					this.emoji= "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/frezee.png";
			 		this.success = false;
			 	break;
 			}
 		}
 	});
 }

}
