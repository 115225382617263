import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class KpiService extends DataService {
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  getActivityLevelChart({ isMunily, idBuilding, date, kpiType }) {
    const param = {
      type: kpiType,
      id: idBuilding,
      date: date,
      chart: 'activityLevel',
      group: null,
    };

    if (isMunily) {
      param.group = 'munily';
    } else {
      param.group = 'building';
    }

    const paramsObject = new HttpParams({
      fromObject: param,
    });

    return this.http
      .get(`${environment.apiUrl}/kpi`, { params: paramsObject })
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
}
