import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipConfig  } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { PushNotificationsModule } from 'ng-push';
import { PushNotificationsModule } from 'ng-push-ivy';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './common-components/common-components.module';
import { reducers } from './component/store/reducers';
import { HttpInterceptorProviders } from './interceptor';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { Init } from './middlewares/init';
import { IsAuthenticated } from './middlewares/is-authenticated';
import { Roles } from './middlewares/roles';
import { SuperAdminRol } from './middlewares/superAdminRol';
import { SuperAdminMunilyPayRol } from './middlewares/superAdminMunilyPayRol';
import { MacroAdminRol } from './middlewares/macroAdminRol';
//SERVICES
import { AreaService } from './services/area.service';
import { AuthService } from './services/auth.service';
import { ChatService } from './services/chat.service';
import { ChatV2Service } from './services/chatv2.service';
import { CommonService } from './services/common.service';
import { CountryService } from './services/country.service';
import { DataService } from './services/data.service';
import { FinanceService } from './services/finance.service';
import { InvitationService } from './services/invitation.service';
import { KpiService } from './services/kpi.service';
import { MessagingService } from './services/messaging.service';
import { NoticeService } from './services/notice.service';
import { UserService } from './services/user.service';
import { PublicService } from './services/public.service';
//COMPONENTS
import { Constants } from './shared/constants/constants';
import { ErrorsDashboard } from './shared/constants/errors.dashboard';
import { Status } from './shared/constants/status';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { NoticesComponent } from './shared/notices/notices.component';
import { ProfileComponent } from './shared/profile/profile.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SpinnerComponent } from './shared/spinner.component';
// import {ChangePasswordComponent} from './shared/change-password/change-password.component';
import * as firebase from 'firebase';
import { SharedModule } from './shared/shared.module';
import { AmplitudeService } from './services/amplitude.service';
import { LayoutMacroCommunityComponent } from './layouts/macro-community/macro-community.component';
import { SidebarMacroCommunityComponent } from './shared/sidebar-macro-community/sidebar-macro-community.component';

firebase.initializeApp(environment.firebase);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
  // return new TranslateHttpLoader(http);
}

// AngularFireModule.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    SidebarComponent,
    // ChangePasswordComponent,
    ProfileComponent,
    NoticesComponent,
    LayoutMacroCommunityComponent,
    SidebarMacroCommunityComponent,
  ],
  imports: [
    SharedModule,
    CommonComponentsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: false, relativeLinkResolution: 'legacy' }),
    PerfectScrollbarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // for database
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    PushNotificationsModule,
    InfiniteScrollModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDvbymJjE3jW7HC4ZhAbJFiDlwRgTGCH7U' }),
    CommonComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, HttpClientModule],
      },
    })
  ],
  providers: [
    /** Interceptors */
    HttpInterceptorProviders,
    GoogleMapsAPIWrapper,
    IsAuthenticated,
    Roles,
    SuperAdminRol,
    MacroAdminRol,
    SuperAdminMunilyPayRol,
    Init,
    DataService,
    AuthService,
    CommonService,
    UserService,
    PublicService,
    NoticeService,
    AreaService,
    FinanceService,
    Constants,
    Status,
    MessagingService,
    ChatService,
    ChatV2Service,
    InvitationService,
    CountryService,
    ErrorsDashboard,
    AmplitudeService,
    NgbTooltipConfig ,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    KpiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
    };
  }
}
