import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-building-sid',
  templateUrl: './building-sid.component.html',
  styleUrls: ['./building-sid.component.scss']
})
export class BuildingSidComponent implements OnInit {

type:string = "";
token:string = "";
generatedBy:string | null = null;
nameSid: string = ''

  constructor( private route:ActivatedRoute, private publicService: PublicService, private router: Router) {
    const params = this.route.snapshot.queryParams;
    this.token = params.token;
    this.type = params.type;
    this.generatedBy = params.generatedBy;
  }
  ngOnInit(): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
      this.getSidName()
    }
    
    getSidName() {
      if ( !this.token ) {
        return;
      }
      this.publicService.getNameSid( this.token )
      .subscribe(
        (res: any) => {
          this.nameSid = res.data.building.name
        },
        err => {
          console.error(err)
        }
        )
}
    
}