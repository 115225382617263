<ng-template #contentCancel let-d="dismiss">
	<div class="modal-body">
		<div class="first-section">
			<img class="" src="https://munily-public-cdn.s3.amazonaws.com/Logos/LOGO_MUNILY_LETRAS_AZULES.png">
		</div>
        <div class="second-section">
			<h1 class="m-0 mr-2">{{ 'userCommunityChange.cancel.title' | translate }}</h1>
		</div>
		<div *ngIf="success === true" class="third-section text-center">
			<p>{{ 'userCommunityChange.cancel.description1' | translate }}<strong>{{ buildingName }}</strong>{{ 'userCommunityChange.cancel.description2' | translate }}</p>
		</div>
		<div *ngIf="success === false" class="third-section text-center">
			<p>{{ 'userCommunityChange.errorCancel.description1' | translate }}<strong>{{ buildingName }}</strong>{{ 'userCommunityChange.errorCancel.description2' | translate }}</p>
		</div>
	</div>
</ng-template>
