import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountingSystems, TmpAccotingConfig, Steps } from 'src/app/interface/accountancy.interface';
import { AmplitudeEvent } from 'src/app/interface/amplitude.interface';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  @Input() statusConfig:TmpAccotingConfig;
  //Events
  @Output() send = new EventEmitter();
  @Output() cancel = new EventEmitter();
  //Form
  form:FormGroup;
  step:number = 1;
  //Variables
  chars:number = 400;
  sentToAmplitude: boolean = false;
  systemSelected:AccountingSystems = null;
  fromTransactions:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private amplitudeService: AmplitudeService,
    private commonService: CommonService,
    private router:Router,
    private userService : UserService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      'methods':[this.statusConfig.paymentMethods ? this.statusConfig.paymentMethods : '', Validators.required],
      'system':[this.statusConfig.systemDescription ? this.statusConfig.systemDescription : '', Validators.required],
    });

    if(this.router.url.search("transactions") != -1){
      this.fromTransactions = true;
      this.step = 3;
    }
    this.systemSelected = this.statusConfig.accoutingSystem as AccountingSystems;
  }

  sendMethods(){
    this.statusConfig['paymentMethods'] = this.form.value.methods;
    this.statusConfig['accoutingSystem'] = this.systemSelected;
    this.statusConfig['systemDescription'] = this.form.value.system;

    this.send.emit({ openModal:'stepper', continue:true, from: 'payments', data: this.statusConfig});
    if (!this.sentToAmplitude) this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.paymentMethodsAdded,
      user_id: this.commonService.getUser().id,
    });
    this.sentToAmplitude = true;
  }

  nextStep(){
    if(this.systemSelected == AccountingSystems.sage50 && this.step == 1){
      this.step = 3;
      this.amplitudSystemSelected();
      return;
    }
    if(this.step < 3){
      if(this.step === 2)this.amplitudSystemSelected();
      this.step++;
    }else{
      this.sendMethods();
    }
  }

  previousStep(){
    if(this.systemSelected == AccountingSystems.sage50 && this.step == 3 && this.statusConfig.step !== Steps.paymentManagement){
      this.step = 1;
      return;
    }
    if(this.step > 1){
      if(this.fromTransactions){
        this.cancelModal();
        return;
      }
      this.step--;
    }else{
      this.cancelModal();
    }
  }

  amplitudSystemSelected(){
    this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.accountingSystemSelected,
      user_id: this.userService.getUser().id,
      event_properties: { 'System Type': this.systemSelected }
    });
  }

  cancelModal(){
    this.cancel.emit({ openModal:'stepper', continue:false, from: 'payments' });
  }

  selectSystem(system:AccountingSystems){
    this.systemSelected = system;
    if( this.systemSelected != AccountingSystems.other){
      this.form.controls.system.setValue(AccountingSystems[system]);
    }else{
      this.form.controls.system.reset();
    }
  }

  charsLeftCount(field:string){
    let maxChars = 400;
    if(maxChars - this.form.controls[field].value.length  > 0)
    this.chars = maxChars - this.form.controls[field].value.length;
  }
}
