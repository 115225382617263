import { Routes } from '@angular/router';
//COMPONENTS
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { Init } from './middlewares/init';
import { LayoutMacroCommunityComponent } from './layouts/macro-community/macro-community.component';
import { MacroAdminRol } from './middlewares/macroAdminRol';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    // redirectTo: '/app',
    pathMatch: 'full',
    canActivate: [Init],
  },
  {
    path: 'public',
    children: [
      {
        path: '',
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
      },
    ],
  },
  {
    path: 'app',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
  {
    path: 'authentication',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
    ],
  },
  {
    path: 'app-community',
    component: LayoutMacroCommunityComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./macro-community/macro-community.module').then(c => c.MacroCommunityModule),
        canActivate: [MacroAdminRol]
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/authentication/404',
  },
];
