import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit} from '@angular/core';
import { PublicService } from "../../services/public.service";
import { ActivatedRoute, Router } from "@angular/router"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.scss']
})

export class ModalNotificationComponent implements OnInit, AfterViewInit {
@ViewChild('contentNotification') templateRef: TemplateRef<any>;
text : string = "¡Su solicitud ha sido aceptada!";
icon : string = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-succes.svg";

token : any = null;
mailtype : any = null;

 constructor(
    private modalService: NgbModal,
    private publicService : PublicService,
    private route : ActivatedRoute,
    private router: Router) {

	this.route.queryParams.subscribe((params:any)=>{
		this.token = params.token ? params.token : this.token;
		this.mailtype = params.mailtype ? params.mailtype : this.mailtype;
		this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
	});
 }

 ngOnInit() {
 	this.publicService.notificationResponse(this.mailtype , this.token).subscribe((resp:any)=>{
 		console.log(resp);
 	},error=>{
 		error
 		console.log(error);
 		this.text = "Error en la solicitud, ¡Por favor comunícate con nosotros!";
		this.icon = "https://munily-public-cdn.s3.amazonaws.com/public/dashboard/icon-wrong.svg";
 	});
 }

 ngAfterViewInit(){
 		this.openDownload(this.templateRef);
 }

 openDownload(content) { //Abre la Modal
 	this.modalService.open(content, { windowClass: 'notification-app-modal', size: 'sm', backdrop:"static", keyboard: false }).result.then((result) => {
 	}, (reason) => {});
 	setTimeout(()=> {
 		let modal = <HTMLElement> document.querySelector(".notification-app-modal .modal-sm");
 		modal.style.height = "-webkit-fill-available";
 		modal.style.display = "flex";
 		modal.style.alignItems = "center";
 	},1)
 	return false;
 }

}
