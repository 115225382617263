<section class="transaction__history">  
  <div class="transaction__history-inputs">

    <div class="clickeables__filters-unit">
      <ng-select bindLabel="accountancy.accountingName" bindValue="_id" [items]="units" placeholder="{{ 'onboarding.transactions.selectUnit' | translate }}"
      [closeOnSelect]="true"   [(ngModel)]="unitSelected" (change)="onEventFilterDate('unit')" >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          {{ item.accountancy.accountingName }}
        </ng-template>
      </ng-select>
    </div>
   
    <div class="clickeables__filters-date">
      <mat-form-field appearance="fill">
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" [placeholder]="'modal.since' | translate" (dateInput)="onEventFilterDate('dates')">
          <input matEndDate formControlName="end" [placeholder]="'modal.until' | translate" (dateInput)="onEventFilterDate('dates')"> 
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
    </div>

  <div class="clickable_filters-clear" *ngIf="isApplyFilter">
      <button class="filter-button-collect" (click)="onEventClearFilter()">
       <span> {{ 'modal.button.cleanFilter' | translate }}</span>
        <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_filter_closed.png" alt="icons filter">
      </button>
    </div>
  </div>

  <section class="history-table" [ngClass]="{'mb-5': countsSelected > 0}">  
    <div (click)="selectAllItems()" class="select-all">
      <img id="selectAll" [src]="imageGeneral" alt="unchecked">
      <p>{{buttonSelect}}</p>
    </div>
  
    <table mat-table *ngIf="accoutingSystem" [dataSource]="dataSource" class="mat-elevation-z8" matSort multiTemplateDataRows (matSortChange)="sortTransactions($event)">
      <!-- Unit Column -->
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell  mat-sort-header sortActionDescription="Sort by unit" *matHeaderCellDef class="pl-5">{{ 'onboarding.transactions.unit' | translate }}</th>
        <td mat-cell *matCellDef="let element;" style="cursor: pointer;" (click)="selectTransaction(element, dataSource.filteredData.indexOf(element))">
          <img [src]="element.src" [id]="dataSource.filteredData.indexOf(element)" alt="unchecked" class="mr-2">
          {{element.unit.accountancy.accountingName}}
        </td>
      </ng-container>
      <!-- Date Column -->
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by paidDay" *matHeaderCellDef>{{ 'onboarding.transactions.date' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span class="history-table__body--date--img"><img src="../../../../assets/images/onboarding/mini-calendar-table.png" alt=""></span>
          <p class="history-table__body--date--p">{{ element.paidDay}}</p>
        </td>
      </ng-container>
      <!-- Transaction Column -->
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by No transaction" *matHeaderCellDef>{{ 'onboarding.transactions.transaction' | translate }}</th>
        <td mat-cell *matCellDef="let element"> #{{element.numberTransaction}} </td>
      </ng-container>
       <!-- Type Column -->
       <ng-container [matColumnDef]="displayedColumns[3]" *ngIf="this.accoutingSystem == 'sage50'">
        <th  mat-header-cell mat-sort-header sortActionDescription="Sort by type of transaction" *matHeaderCellDef>{{ 'onboarding.transactions.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ ( element.type == 'SJ' && element.debitAmount ? 'onboarding.transactions.bill' : 'onboarding.transactions.payment' ) | translate }} </td>
      </ng-container>
      <!-- Amount Column -->
      <ng-container [matColumnDef]="displayedColumns[4]" *ngIf="this.accoutingSystem == 'sage50'">
        <th mat-header-cell *matHeaderCellDef>{{ 'onboarding.transactions.amount' | translate }}</th>
        <td mat-cell *matCellDef="let element"><p>{{(element.type == 'SJ' && element.debitAmount ? element.debitAmount : element.creditAmount) | currency:'USD':'symbol-narrow':'.2-2'}}</p></td>
      </ng-container>
      <!-- Debit Column -->
      <ng-container [matColumnDef]="displayedColumns[3]" *ngIf="this.accoutingSystem == 'other'">
        <th mat-header-cell *matHeaderCellDef>{{ 'onboarding.transactions.debit' | translate }}</th>
        <td mat-cell *matCellDef="let element"><p>{{ element.debitAmount | currency:'USD':'symbol-narrow':'.2-2' }}</p></td>
      </ng-container>
      <!-- Credit Column -->
      <ng-container [matColumnDef]="displayedColumns[4]" *ngIf="this.accoutingSystem == 'other'">
        <th mat-header-cell *matHeaderCellDef>{{ 'onboarding.transactions.credit' | translate }}</th>
        <td mat-cell *matCellDef="let element"><p>{{ element.creditAmount | currency:'USD':'symbol-narrow':'.2-2'}}</p></td>
      </ng-container>
      <!-- Balance Column -->
      <ng-container [matColumnDef]="displayedColumns[5]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by balance" *matHeaderCellDef>{{ 'onboarding.transactions.balance' | translate }}</th>
        <td mat-cell *matCellDef="let element"><p>{{element.balance | currency:'USD':'symbol-narrow':'.2-2'}}</p></td>
      </ng-container>
      <!-- Empty Column -->
      <ng-container [matColumnDef]="displayedColumns[6]">
        <th mat-header-cell sortActionDescription="Sort by balance" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>
      <!-- expand Column -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="pagination">
      <mat-paginator [length]="pagination.totalDocs" [pageSize]="pagination.size"
        aria-label="Select page" showFirstLastButtons (page)="onPageEvent($event)">
      </mat-paginator>
    </div>
  </section>
</section> 

<section class="payment-footer" *ngIf="countsSelected > 0">
  <div class="payment-footer__selected">
    <p>{{ 'onboarding.managment.payment-footer' | translate: {count:countsSelected} }}</p>
  </div>
  <div class="payment-footer__button">
    <button (click)="exportTransactions()">{{ 'onboarding.transactions.export' | translate }}</button>
  </div>
</section>
