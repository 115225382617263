<div class="payment-section" >
  <div class="container-inside">
    <!-- Paso 1 Seleccion del sistema contable -->
    <div class="text-content accounting-method" *ngIf="step == 1">
      <h1 class="col-12"> {{ 'payment-modal.accounting-method.title' | translate }} </h1>
      <p>{{ 'payment-modal.accounting-method.desc' | translate }} </p>
      <div class="input-section" [formGroup]="form">
        <input type="radio" class="btn-check" name="options" id="option-1" autocomplete="off" (click)="selectSystem('sage50')">
        <label class="accounting-system" for="option-1" [ngClass]="{'checked': systemSelected === 'sage50'}">
          <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/sage50Logo.svg" alt="Sage50">
        </label>
        <input type="radio" class="btn-check" name="options" id="option-2" autocomplete="off" (click)="selectSystem('other')">
        <label class="accounting-system" [ngClass]="{'checked': systemSelected === 'other'}" for="option-2">{{ 'payment-modal.accounting-method.otherSystem' | translate }}</label>
      </div>
    </div>

    <!-- Paso 2 Describir sistema contable -->
    <div class="text-content accounting-method" *ngIf="step == 2">
      <h1 class="col-12"> {{ 'payment-modal.accounting-method.title' | translate }} </h1>
      <p>{{ 'payment-modal.accounting-method.desc' | translate }} </p>
      <div class="input-section" [formGroup]="form">
        <div class="col-3">
          <label class="accounting-system checked" style="color: #388AED;">{{ 'payment-modal.accounting-method.otherSystem' | translate }}</label>
        </div>
        <div class="col-9">
          <p class="chars-left"> {{chars}} {{ 'payment-modal.text-content.characters' | translate }}</p>
          <textarea (keyup)="charsLeftCount('system')" id="text-area-2" class="form-control" maxlength="401" type="number" formControlName="system" placeholder="{{ 'payment-modal.accounting-method.placeholder' | translate }}"></textarea>
        </div>
      </div>
    </div>

    <!-- Paso 3 Metodos de pago  -->
    <div class="text-content payment-method" *ngIf="step == 3">
      <h1 class="col-12">{{ 'payment-modal.text-content.title' | translate }}</h1>
      <p>{{ 'payment-modal.text-content.desc' | translate }}</p>
      <div class="input-section" [formGroup]="form">
        <p class="chars-left"> {{chars}} {{ 'payment-modal.text-content.characters' | translate }}</p>
        <textarea (keyup)="charsLeftCount('methods')" id="text-area" class="form-control" maxlength="401" type="number" formControlName="methods" placeholder="{{ 'payment-modal.text-content.placeholder' | translate }}"></textarea>
      </div>
    </div>

  </div>
</div>
<div class="footer row">
  <div class="button-line-inside col-4">
    <button class="back" (click)="previousStep()">
      <img class="pl-1" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_black.svg" alt="arrow"> {{ 'buttons.return' | translate }}
    </button>
  </div>
  <div class="button-line-inside col-4 offset-4">
    <button class="next active" (click)="nextStep()" [ngClass]="{
      'active': ( step == 1 && systemSelected != null ) || ( step == 2 && form.controls.system.valid ) || ( step == 3 && form.valid ),
      'inactive': ( step == 1 && systemSelected == null ) || ( step == 2 && form.controls.system.invalid ) || ( step == 3 && form.invalid )}"
      [disabled]="( step == 1 && systemSelected == null ) || ( step == 2 && form.controls.system.invalid ) || ( step == 3 && form.invalid )">
      <span *ngIf="step < 3">{{ 'buttons.next' | translate }}</span>
      <span *ngIf="step === 3">{{ 'buttons.accept' | translate }}</span>
      <img class="pl-1" *ngIf="form.valid" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_white.png" alt="arrow">
      <img class="pl-1" *ngIf="form.invalid" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_gray.png"  alt="arrow">
    </button>
    </div>
</div>
