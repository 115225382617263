<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <li class="nav-item d-none d-md-block">
    <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
      <i class="fa fa-bars fa-2x" style="color: #388ff0"></i>
    </a>
  </li>
  <li *ngIf="param" class="nav-item d-none d-md-block mx-auto my-auto">
    <div class="alert alert-warning my-0 px-2 py-1" role="alert">
      {{ 'header.notification.plan' | translate: param }}
    </div>
  </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav navigationOriginal" [ngClass]="module != headerModules.accountState ? 'navigationOriginalAccouting' : 'navigationOriginal'">
  <div *ngIf="module != headerModules.accountState" class="d-flex justify-content-between align-items-center mr-auto">
    <div class="buildingName mr-5">
      <p class="m-0">
        {{building.name}}
      </p>
    </div>
    <div>
      <!-- <button *ngIf="lastTransactionUpdate" class="lastTransactionUpdateButton btn btn-outline-success d-flex">
        <img src="https://munily-public-cdn.s3.amazonaws.com/accountingSystem/check_circle.png" alt="">
        <p class="m-0 ml-1">{{ 'pqrs.create.lastUpdate' | translate }}: {{lastTransactionUpdate}}</p>
      </button> -->
    </div>
  </div>

  <div *ngIf="module == headerModules.accountState" class="d-flex justify-content-between align-items-center">
    <div class="accountStateTitle mr-5">
      <p class="m-0">
        {{ 'building.tab.settings.accountStatus' | translate}}
      </p>
    </div>
    <div>
      <!-- <button *ngIf="lastTransactionUpdate" class="lastTransactionUpdateButton btn btn-outline-success d-flex">
        <img src="https://munily-public-cdn.s3.amazonaws.com/accountingSystem/check_circle.png" alt="">
        <p class="m-0 ml-1">{{ 'pqrs.create.lastUpdate' | translate }}: {{lastTransactionUpdate}}</p>
      </button> -->
    </div>
  </div>
  
  <div class="d-flex align-items-center">
    <div ngbDropdown class="d-inline-block languageDropdown nav-item dropdown custom-item-nav d-flex align-items-center px-3">
      <button class="btn btn-outline-primary" id="languageSelect" ngbDropdownToggle>
        <img class="imgFlag" [src]="curLangObj?.img" [alt]="curLangObj?.lang" /> {{ curLangObj?.lang }}
      </button>
      <div ngbDropdownMenu aria-labelledby="languageSelect">
        <button ngbDropdownItem *ngFor="let language of languages" (click)="useLanguage(language)">
          <img class="imgFlag" [src]="language.img" alt="english" /> {{ language.lang }}
        </button>
      </div>
    </div>

    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" aria-haspopup="true" aria-expanded="false">
        <img src="https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/bell.svg" height="27" width="21" />
        <span
          class="position-absolute p-1 border border-light rounded-circle notificationAlert"
          *ngIf="unreadNotices + (messagingService.notificationsMessage ? messagingService.notificationsMessage.length : 0) > 0"
        >
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown custom-dropdwon-menu" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-primary"></span>
        </span>
        <ul class="list-style-none">
          <li>
            <div class="drop-title bg-primary text-white">
              <h4 class="m-b-0 m-t-5">
                {{ unreadNotices + (messagingService.notificationsMessage ? messagingService.notificationsMessage.length : 0) }}
                {{ 'header.notification.new' | translate }}
              </h4>
              <span class="font-light">{{ 'dashboard.notification' | translate }}</span>
            </div>
          </li>
          <li>
            <div class="message-center notifications" [perfectScrollbar]="config">
              <div id="messages-notifications" *ngIf="messagingService.notificationsMessage && messagingService.notificationsMessage.length > 0">
                <a
                  href="javascript:void(0)"
                  class="message-item unread-notice"
                  *ngFor="let notificationM of messagingService.notificationsMessage"
                  (click)="goMessage(notificationM)"
                >
                  <span class="mail-contnet">
                    <h6 class="message-title text-uppercase">{{ notificationM.title }}</h6>
                    <span class="mail-desc">{{ notificationM.body }}</span>
                    <span class="time text-uppercase">{{ notificationM.createdAt }}</span>
                  </span>
                </a>
              </div>
              <a
                href="javascript:void(0)"
                class="message-item"
                [ngClass]="{ 'unread-notice': !notification.read }"
                *ngFor="let notification of notifications"
                (click)="readNotice(notification.id, notification.read, notification.activity)"
              >
                <!--<span class="btn btn-circle {{notification.btn}}">
                    <i class="{{notification.icon}}"></i>
                </span>-->
                <span class="mail-contnet">
                  <h6 class="message-title text-uppercase">{{ notification.title }}</h6>
                  <span class="mail-desc">{{ notification.body }}</span>
                  <span class="time text-uppercase">{{ notification.createdAt }}</span>
                </span>
              </a>
            </div>
          </li>
          <!--<li>
            <a class="nav-link text-center m-b-5 text-primary" href="javascript:void(0);">
              <strong>{{'header.notification.checkAll' | translate}}</strong>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>-->
        </ul>
      </div>
    </li>
    <!-- ============================================================== -->
    <!-- End Comment -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
        data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <i class="font-24 mdi mdi-comment-processing" style="color: gray"></i>

      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2" ngbDropdownMenu>
              <span class="with-arrow">
                  <span class="bg-danger"></span>
              </span>
        <ul class="list-style-none">
          <li>
            <div class="drop-title text-white bg-danger">
              <h4 class="m-b-0 m-t-5">5 New</h4>
              <span class="font-light">Messages</span>
            </div>
          </li>
          <li>
            <div class="message-center message-body" [perfectScrollbar]="config">
              <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                              <span class="user-img">
                                  <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                  <span class="profile-status {{mymessage.status}} pull-right"></span>
                              </span>
                <span class="mail-contnet">
                                  <h5 class="message-title">{{mymessage.from}}</h5>
                                  <span class="mail-desc">{{mymessage.subject}}</span>
                                  <span class="time">{{mymessage.time}}</span>
                              </span>
              </a>
            </div>
          </li>
          <li>
            <a class="nav-link text-center link" href="javascript:void(0);">
              <b>See all e-Mails</b>
              <i class="fa fa-angle-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item dropdown custom-item-nav" ngbDropdown placement="bottom-right">
      <a class="nav-link dropdown-toggle text-muted">{{ userName }}</a>
    </li> -->
    <li class="nav-item dropdown profile" ngbDropdown placement="bottom-right">
      <a
        ngbDropdownToggle
        class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
        href="javascript:void(0)"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="button-content">
          <a class="nav-link dropdown-toggle text-muted col-8">{{ userName }}</a>
          <img src="{{ photo }}" alt="user" class="rounded-circle photo-user" width="31" />
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY custom-dropdwon-menu" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-primary"></span>
        </span>
        <div class="d-flex no-block align-items-center bg-primary text-white m-b-10">
          <div class="">
            <img src="{{ photo }}" alt="user" class="img-circle"/>
          </div>
          <div class="m-l-10">
            <h4 class="m-b-0">{{ userName }}</h4>
            <p class="m-b-0">{{ userEmail }}</p>
          </div>
        </div>
        <a class="dropdown-item" href="javascript:void(0)" (click)="openModalProfile()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-profile.svg" alt="password"> {{ 'header.profile.myProfile' | translate }}</a
        >
        <a class="dropdown-item" href="javascript:void(0)" (click)="openModalPassword()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-password.svg" alt="password"> {{ 'header.password.title' | translate }}</a
        >
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-loggout.svg" alt="logout"> {{ 'header.profile.logout' | translate }}</a
        >
      </div>
    </li>
    <li class="nav-item dropdown custom-item-nav multis" placement="bottom-right">
      <app-multisession-sidebar></app-multisession-sidebar>
    </li>
  </div>
</ul>

<div *ngIf="module == headerModules.accountState" class="buttonsClick">
  <button [disabled]="onboardingIsNotComplete" #payInp (click)="clickButtonAccounting(accountingButtons.paymentManagment, payInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.paymentManagment ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.selfName' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #updInp (click)="clickButtonAccounting(accountingButtons.updateAccounting, updInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.updateAccounting ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.update' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #traInp (click)="clickButtonAccounting(accountingButtons.transactions, traInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.transactions ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.transactions.see' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #impInp (click)="clickButtonAccounting(accountingButtons.importedFiles, impInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.importedFiles ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.importFiles' | translate }}</button>
  
  <div class="dropdown btn">
    <button 
    [disabled]="onboardingIsNotComplete"
    #editInp
    (click)="clickButtonAccounting('button', editInp)"
    class="btn btnAccountingState dropdown-toggle"
    [ngClass]="typeButton ? 'btnAccountingActive' : 'btnAccounting'"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    >
      {{ 'modal.edit' | translate }}
    </button>
    <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editDueDate)">{{ 'onboarding.transactions.editExpired' | translate }}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editUnit)">{{ 'onboarding.transactions.editUnits' | translate }}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editPaymentMethod)">{{ 'onboarding.transactions.editPayment' | translate }}</a>
    </div>
  </div>

</div>


<app-change-password #changePassword></app-change-password>

<app-profile #profile (onConfirm)="onConfirm($event)"></app-profile>

<ng-template #logoutModal let-c="close" let-d="dismiss">
  <app-logout-modal [allSessions]="true" (close)="logoutModalResponse($event)"></app-logout-modal>
</ng-template>
