<div class="login-modal">
  <div class="header">
    <h3 class="title"><img class="mr-1" src="https://munily-public-cdn.s3.amazonaws.com/general/icons/add_icon.png" alt="edit">{{ 'settings.addAccount' | translate}}</h3>
    <button class="btn closeButton" (click)="closeModal({logged:false})"><img src="https://munily-public-cdn.s3.amazonaws.com/general/icons/closeButton.svg" alt="close" height="27.5"></button>
  </div>
  <form class="steps-content" [formGroup]="form" *ngIf="form">
    <div class="form-row">
      <div class="form-group col-12">
        <label for="roles">{{ 'superadministration.editUser.mail.label' | translate}}*</label>
        <input type="text"
          [class.is-invalid]="form.controls.email.invalid && form.controls.email.dirty && summited"
          class="form-control"
          id="email"
          placeholder="{{'authentication.placeholder.insertEmail' | translate}}"
          formControlName="email"
          id="validationServer01"
          aria-describedby="validationServer01Feedback"
        >
        <div id="validationServer01Feedback" class="invalid-feedback">
          <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/exclamationSing.svg" alt="exclamation">
          {{ mailError | translate }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="roles">{{'authentication.password' | translate}}*</label>
        <input type="password"
          [class.is-invalid]="form.controls.password.invalid && form.controls.password.dirty && summited"
          class="form-control"
          id="password"
          placeholder="{{'authentication.placeholder.password' | translate}}"
          formControlName="password"
          id="validationServer02"
          aria-describedby="validationServer02Feedback"
        >
        <div id="validationServer02Feedback" class="invalid-feedback">
          <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/exclamationSing.svg" alt="exclamation">
          {{'authentication.error.invalidPassword' | translate }}
        </div>
      </div>
    </div>
    <div class="button-section m-auto">
      <button class="btn btn-next w-100" (click)="login()">{{ 'authentication.login' | translate}}</button>
    </div>
  </form>
</div>
