import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TimeLapses } from '../interface/data.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeComponentsService {

  constructor(private http: HttpClient) {}

  // FASTACCESS COMPONENT
  getNotificationsFastAccess(){
  	return this.http.get(`${environment.apiUrl}/admin/v1/dashboard/notifications`);
  }
  getNotificationsPreRegister(){
  	return this.http.get(`${environment.apiUrl}/admin/v1/tmp-users/notification`);
  }
  getNotificationsChats(){
  	return this.http.get(`${environment.apiUrl}/admin/v1/chat/notification`);
  }
  getNotificationsReservations(){
  	return this.http.get(`${environment.apiUrl}/admin/v1/reservations/notification`);
  }

  //RESERVATION CHART
  getReservations(data:any){
    let params = new HttpParams().set('startDate',data.startDate).set("endDate",data.endDate);
    return this.http.get(`${environment.apiUrl}/admin/v1/events/reservations/info`,{params : params});
  }

  //ACTIVATION CHART COMPONENT
  getResidenceActivity(){
    return this.http.get(`${environment.apiUrl}/admin/v1/units/use`);
  }

  //PREREGISTRO RESPONSE
  getPreregisterTime(){
    return this.http.get(`${environment.apiUrl}/admin/v1/tmp-users/info`);
  }

  //USE-RESIDENT COMPONENT
  getResidentsActivity(search:any,pagination:any, roles:string[]){
    let params = new HttpParams().set("page",pagination.page).set("size",pagination.size).set("roles",JSON.stringify(roles));
    search ? params = params.set('search',search): null;
    return this.http.get(`${environment.apiUrl}/admin/v1/events/full-text`,{params : params});
  }

  //VISITS
  getInvitations(type:string){
    let params = new HttpParams().set('type',type);
    return this.http.get(`${environment.apiUrl}/admin/v1/events/invitations/info`,{params : params});
  }

  //CHAT TIME RESPONSE
  getChatTime(){
    return this.http.get(`${environment.apiUrl}/admin/v1/chat/response-time`);
  }

  //GET UPDATE BUILDING DATE
  getRefreshBuildingConfiguration(){
    return this.http.get(`${environment.apiUrl}/admin/v1/dashboard/user/refresh`);
  }

  //SHARED FUNCTION
  getTimeOfMinutes(minutes:any){
    if (!minutes || minutes == 'undefined') {
      return '00:00:00';
    }else{
      let hours:string = String( Math.floor(minutes / 60));
      let days:string = String( Math.floor((minutes / 60)/24))
      let min:string = String( Math.floor(minutes % 60));
      let sec:any = String(minutes % 60).split('.')[1]
      if(sec){
        sec = 60 * (sec / 10)
        sec = sec.toFixed(0).toString();
        sec.length > 1 ? sec = sec.slice(0,2) : sec = '0' + sec;
      }else{
        sec = '00'
      }

      hours = hours.length >= 2 ? 
        Number(hours) > 24 ? 
        `${days}D ${ Math.floor(24 * Number("0." + String((minutes / 60)/24).split('.')[1]))}`:
        hours
        :'0' + hours;
      min = min.toString().length >= 2 ? min : '0' + min;
      return `${hours}:${min}:${sec}`;
    }
  }

  //Calls Chart
  getCallDataToGraph(time:TimeLapses){
    let params = new HttpParams().set('time',time);
    return this.http.get(`${environment.apiUrl}/admin/v1/dashboard/calls`, {params : params});
  }
}
