import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ACTION_LOADING, SHOW_MODAL } from 'src/app/component/store/actions/actions';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { NoticesV2Service } from 'src/app/services/notices-v2.service';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit {
  showModal$: Observable<any>;
  interacciones: any[] = [];
  showPanel: boolean;
  noticeId: string;
  page = 1;
  search = '';
  finishPage = 10;
  paginationQuery: { page: number, rowsPerPage: number, sortOrder?: number, sortField?: string, searchUser?: string } = {
    page: 1,
    rowsPerPage: 10,
    sortOrder: 1,
    sortField: 'updatedAt'
  }

  constructor(
    public dataService: DataService,
    private noticesV2Service: NoticesV2Service,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.showModal$ = this.dataService.getshowModal();
    this.showModal$.subscribe((state) => {
      this.showPanel = state.show;
      if (!this.showPanel) this.paginationQuery.searchUser = null;
      if (state.noticeId !== '' && state.noticeId !== '0' && state.noticeId !== this.noticeId) {
        this.noticeId = state.noticeId;
        this.getInteractionNotices(this.paginationQuery);
      } else if (state.noticeId == this.noticeId) {
        return;
      } else {
        this.interacciones = [];
      }
    });
  }

  validation(interacciones) {
    interacciones.forEach((element) => {
      if (!element.emoji) {
        element.emoji = { code: '', ico: '' };
      }
      if (!element.email) {
        element.email = { sent: false, read: false };
      }
      if (!element.notification) {
        element.notification = { sent: false, read: false };
      }
    });
  }

  getInteractionNotices(query: { page: number, rowsPerPage: number, sortOrder?: number, sortField?: string, searchUser?: string }) {
    this.noticesV2Service.getInteractionNotices(this.noticeId, query).then((resp: any) => {
      this.noticesV2Service.isLoading({ action: ACTION_LOADING, payload: false });

      if (this.paginationQuery.page === 1) {
        this.interacciones.length = 0;
        this.interacciones = resp.data.docs;
      }
      if (this.paginationQuery.page > 1) this.interacciones = [...this.interacciones, ...resp.data.docs]

      if (resp.data.totalPages) {
        this.finishPage = resp.data.totalPages;
      }
      this.validation(this.interacciones);
    });

  }

  closePanel() {
    const data = { show: false, noticeId: '0' };
    this.noticeId = '';
    this.search = '';
    this.paginationQuery.page = 1;
    this.commonService.showModal({ action: SHOW_MODAL, payload: data });
  }

  onScroll(e) {
    if (this.paginationQuery.page < this.finishPage) {
      this.paginationQuery.page = this.paginationQuery.page + 1;
      this.getInteractionNotices(this.paginationQuery);
    }
  }

  addInteraction() {
    this.noticesV2Service.getListInteractionNotices(this.noticeId, this.page, this.search).then((resp: any) => {
      let array = resp.data;
      array.forEach((element) => {
        this.interacciones.push(element);
      });
      this.validation(this.interacciones);
    });
  }

  searchInteraction() {
    this.paginationQuery.page = 1;
    this.paginationQuery.searchUser = this.search;

    if (this.search.length == 0) {
      this.paginationQuery.page = 1;
    }
    this.getInteractionNotices(this.paginationQuery)
  }
}
