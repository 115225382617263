import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class FinanceService extends DataService {
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  storeDebt(data) {
    return this.http
      .post(`${environment.apiUrl}/debt`, data)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  storeCondonation(data) {
    return this.http
      .post(`${environment.apiUrl}/condonation`, data)
      .toPromise()
      .then((res: any) => {
        return res.object;
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  fetchCondonations(page, limit, search?) {
    const params = search ? `&${search}` : '';
    return this.http
      .get(`${environment.apiUrl}/condonation?page=${page}&rowsPerPage=${limit}${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        Promise.reject(err);
      });
  }

  fetchUnitDebt(page, limit, search?) {
    const params = search ? `&${search}` : '';
    return this.http
      .get(`${environment.apiUrl}/debt?page=${page}&rowsPerPage=${limit}${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        Promise.reject(err);
      });
  }

  fetchDebts(number?) {
    const params = number ? `?unit=${number}` : '';
    return this.http
      .get(`${environment.apiUrl}/debt${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  fetchAggregateDebts(page, limit, search?) {
    const params = search ? `&${search}` : '';
    return this.http
      .get(`${environment.apiUrl}/aggregator-debt?page=${page}&rowsPerPage=${limit}${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        Promise.reject(err);
      });
  }

  getTypeDebt(type, sortField?, sortOrder?) {
    let params = '';
    params += sortField ? `&${sortField}` : '';
    params += sortOrder ? `&${sortOrder}` : '';
    return this.http
      .get(`${environment.apiUrl}/catalog?type=${type}${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  uploadCSVDebt(file, fields, checked) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('checked', checked);
    const keys = Object.keys(fields);
    keys.forEach((k) => {
      formdata.append(`fields[${k}]`, fields[k]);
    });
    return this.http
      .post(`${environment.apiUrl}/debt/upload-csv`, formdata)
      .toPromise()
      .then((res: any) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
}
