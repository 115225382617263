import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class AreaService extends DataService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);

    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  fetchArea(find?) {
    find = find ? `?name=${find}` : '';
    return this.http
      .get(`${environment.apiUrl}/admin/v1/areas?status=all${find}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getAreas(id, all?, search?, page: any = 1, status?: string) {
    let size = all ? `size=${100}` : '';
    page = `&page=${page}`;
    search = search ? `&search=${search}` : '';
    status = status ? `&status=${status}` : '';
    return this.http.get(`${environment.apiUrl}/super/v1/areas/${id}?${size}${search}${page}${status}`, { headers: this.headers });
  }

  getAllAreasByStatus(id: string, status: string) {
    let params = new HttpParams().set('size', 100);
    if (status) params = params.set('status', status);
    return this.http.get(`${environment.apiUrl}/super/v1/areas/${id}`, { headers: this.headers, params });
  }

  getAreaById(id) {
    return this.http.get(`${environment.apiUrl}/admin/v1/areas/${id}`);
  }

  viewArea(id) {
    return this.http
      .get(`${environment.apiUrl}/admin/v1/areas/${id}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  storeArea(data) {
    const building = this.getUser().buildings[0];
    data.area = {
      ...data.area,
      building_id: building._id,
    };

    return this.http
      .post(`${environment.apiUrl}/area`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  newStoreArea(data) {
    const building = this.getUser().buildings[0];

    data = {
      ...data.area,
      building: building._id,
    };

    return this.http
      .post(`${environment.apiUrl}/admin/v1/areas`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateArea(data) {
    const building = this.getUser().buildings[0];
    data = {
      ...data,
      building,
    };

    return this.http
      .put(`${environment.apiUrl}/area/${data.area._id}`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  newUpdateArea(data) {
    const building = this.getUser().buildings[0];
    data = {
      ...data.area,
      building,
    };
    return this.http
      .put(`${environment.apiUrl}/admin/v1/areas/${data.id}`, data, { headers: { areaId: data.id } })
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteArea(areaId) {
    return this.http.delete(`${environment.apiUrl}/admin/v1/areas/${areaId}`);
  }

  uploadImageArea(file) {
    return this.http
      .post(`${environment.apiUrl}/area/file`, file)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  fetchAvailableScheduleByDate(id, date) {
    return this.http
      .get(`${environment.apiUrl}/admin/v1/reservations/areas/${id}/calendar/date/${date}`)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  fetchScheduleData(id, date, hour) {
    return this.http
      .get(`${environment.apiUrl}/admin/v1/reservations/areas/${id}/calendar/date/${date}/hour/${hour}`)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getImagesofAreas() {
    return this.http.get(`${environment.apiUrl}/admin/v1/areas/images/get`);
  }
}
