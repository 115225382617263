import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SafeHtmlPipe } from '../pipes/safeHtmlPipe.pipe';
import { ApartmentService } from '../services/apartment.service';
import { PlanService } from '../services/plan.service';
import { NgbDateCustomParserFormatter } from '../shared/ngb-datepicker-formatter/ngbDateCustomParserFormatter';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmptyDatatableComponent } from './empty-datatable/empty-datatable.component';
import { LoadingComponent } from './loading.component';
import { MetricChartComponent } from './metric-chart/metric-chart.component';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { ModalMapBuildingsComponent } from './modal-map-buildings/modal-map-buildings.component';
import { TableMasterComponent } from './table-master/table-master.component';
import { CommonFiltersComponent } from './common-filters/common-filters.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { ModalComponent } from './modal/modal.component';
import { BuildingMacroCommunityService } from '../macro-community/services/building.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UserAdministrationService } from '../macro-community/services/administration/user.service';
import { MAT_DATE_LOCALE } from '@angular/material/core'

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
  // return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    NgbModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    ModalConfirmationComponent,
    TableMasterComponent,
    LoadingComponent,
    AdvanceSearchComponent,
    SafeHtmlPipe,
    ChangePasswordComponent,
    MetricChartComponent,
    EmptyDatatableComponent,
    ModalMapBuildingsComponent,
    CommonFiltersComponent,
    DynamicTableComponent,
    ModalComponent,
  ],
  exports: [
    ModalConfirmationComponent,
    LoadingComponent,
    AdvanceSearchComponent,
    SafeHtmlPipe,
    ChangePasswordComponent,
    MetricChartComponent,
    EmptyDatatableComponent,
    ModalMapBuildingsComponent,
    CommonFiltersComponent,
    DynamicTableComponent,
    ModalComponent,
  ],
  providers: [
    PlanService,
    ApartmentService,
    BuildingMacroCommunityService,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
    UserAdministrationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } // DD/MM/YYYY
  ],
})
export class CommonComponentsModule {}
