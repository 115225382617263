<section class="transactions transactionsPage">
  <section>
    <app-transactions #transactions [statusConfig]="statusConfig" *ngIf="transactions" 
    [units]="units"  
    (emitSelectedUnit)="getEmitPaymentSelectedUnit($event)"
    ></app-transactions>
    <app-payment-managment
      *ngIf="payment"
      #payments
      [statusConfig]="statusConfig"
      [units]="units"
      (emitSelectedUnit)="getEmitPaymentSelectedUnit($event)"
    ></app-payment-managment>
  </section>
</section>

<ng-template #bills let-c="close" let-d="dismiss">
  <app-bills-modal [statusConfig]="statusConfig" (cancel)='closeModal($event)' (send)='updateStatusFromModals($event)'></app-bills-modal>
</ng-template>

<ng-template #paymentModal let-c="close" let-d="dismiss">
  <app-payment-methods [statusConfig]="statusConfig" (cancel)='closeModal($event)' (send)='updateStatusFromModals($event)'></app-payment-methods>
</ng-template>
