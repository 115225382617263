<button type="button" mat-button (click)="changeView()" *ngIf="!currentSession.superUser">
  <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/options.svg" alt="options">
</button>

<div class="container-backdrop" *ngIf="showSideBar">
  <div class="side-bar rigth" [ngClass]="{'inside': showSideBar && slideIn }">
    <div class="header">
      <h3 class="title col-10">{{'authentication.multisessions' | translate }}</h3>
      <button class="btn closeButton col-2" (click)="changeView()"><img src="https://munily-public-cdn.s3.amazonaws.com/general/icons/closeButton.svg" alt="close"></button>
    </div>
    <div class="search-section d-flex ">
      <input type="text" class="form-control col-10" placeholder="{{'authentication.placeholder.searchAccounts' | translate }}" [(ngModel)]="search" name="search">
      <div class="col-2">
        <button class="btn btn-primary" (click)="openModal('login')"><img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/plus-mark.svg" alt="close"></button>
      </div>
    </div>
    <ng-container *ngIf="sessions.length > 0; else emptyStateNotAccounts">
      <div class="accounts-sections">
        <div class="filters">
          <h3 class="col-6">{{ 'authentication.accounts' | translate }}: {{sessions.length}}</h3>
          <span class="col-4 text-end">
            <button mat-button [matMenuTriggerFor]="menu" aria-label="sortByLetters" class="mr-2">
              {{'modal.selectInput.all' | translate}}
              <i class="fa fa-solid fa-chevron-down"></i>
            </button>
            <mat-menu #menu="matMenu" class="alphabet-menu">
              <button mat-menu-item (click)="restoreFilters()">{{ 'filterBy' | translate }} : {{ 'all' | translate }}</button>
              <mat-checkbox class="checkbox-container" *ngFor="let letter of alphabet" [(ngModel)]="checkboxControl[letter]" (change)="check(letter)">{{letter | uppercase}}</mat-checkbox>
            </mat-menu>
          </span>
          <span class="col-2">
            <img *ngIf="sort == 'asc'" (click)="sort = 'desc'" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/sort-desc.svg" alt="sort-desc">
            <img *ngIf="sort == 'desc'" (click)="sort = 'asc'" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/sort-asc.svg" alt="sort-asc">
          </span>
        </div>
        <div class="sessions-list">
          <ul class="list">
            <ng-container *ngFor="let letter of (sort == 'asc') ? (orderedSessions | keyvalue).reverse() : orderedSessions | keyvalue">
              <ng-container *ngIf="(filterBy.length > 0 && filterBy.includes(letter.key)) || filterBy.length === 0">
                <li class="letter" *ngIf="!search"><span>{{letter.key | uppercase}}</span></li>
                <li class="session" *ngFor="let session of orderedSessions[letter.key] | filter: search">
                  <div class="session-data" (click)="currentSession.id != session.id ? changeCurrentUser(session) : null">
                    <img class="avatar" [src]="session.photoUrl ?? 'https://munily-public-cdn.s3.amazonaws.com/general/pipes/dashboard/admin.png'" alt="user">
                    <p class="username">{{session.name}}</p>
                  </div>
                  <span class="logout" (click)="openModal('logout', session)" *ngIf="currentSession.id != session.id">
                    <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/exits.svg" alt="exits">
                  </span>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loginModal let-c="close" let-d="dismiss">
  <app-login-modal (close)="closeModal($event)"></app-login-modal>
</ng-template>

<ng-template #logoutModal let-c="close" let-d="dismiss">
  <app-logout-modal [allSessions]="false" [sessionSelected]="sessionSelected" [sessions]="sessions" (close)="closeModal($event)"></app-logout-modal>
</ng-template>

<ng-template #emptyStateNotAccounts>
  <div class="empty-state">
    <div>
      <img class="mb-1" src="https://munily-public-cdn.s3.amazonaws.com/general/empty-state.svg" alt="empty">
      <h2 class="title">{{ 'authentication.empty-states.not-account.title' | translate }}</h2>
      <p class="description">{{ 'authentication.empty-states.not-account.description' | translate }}</p>
      <button class="btn btn-primary" (click)="openModal('login')">{{ 'authentication.addAcount' | translate }}</button>
    </div>
  </div>
</ng-template>
