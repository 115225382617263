import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { ACTION_LOADING } from '../../component/store/actions/actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('content', {static: true}) bodyContent: ElementRef;

  modalRef: any;
  form: FormGroup;
  loading = false;
  needChange = false;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {}

  initForm() {
    this.form = this.fb.group({
      currentPassword: [null, null],
      password: [null, null],
      confirmPassword: [null, null],
    });
  }

  onSubmit() {
    if (this.valid()) {
      this.authService.isLoading({ action: ACTION_LOADING, payload: true });
      const info = this.form.getRawValue();
      if (info.password.localeCompare(info.confirmPassword) === 0) {
        this.authService
          .resetPassword(info.currentPassword, info.password)
          .then((r) => {
            if (r) {
              this.toastr.success('Contraseña actualizada con éxito');
              this.closeModal();
            }
            this.authService.isLoading({ action: ACTION_LOADING, payload: false });
          })
          .catch((err) => {
            this.authService.isLoading({ action: ACTION_LOADING, payload: false });
            this.toastr.error('Contraseña actual incorrecta');
          });
      } else {
        this.authService.isLoading({ action: ACTION_LOADING, payload: false });
        this.toastr.error('Las contraseñas no coinciden');
      }
    }
  }

  // onSubmit() {
  //   if (this.valid()) {
  //     this.authService.isLoading({action: ACTION_LOADING, payload: true});
  //     const info = this.form.getRawValue();
  //     this.authService.sendResetPassword(info.currentPassword).then(r => {
  //       if (r) {
  //         this.toastr.success(this.translate.instant('toastrMessage.success.emailSent'));
  //         this.closeModal();
  //       }
  //       this.authService.isLoading({action: ACTION_LOADING, payload: false});
  //     }).catch(err => {
  //       this.authService.isLoading({action: ACTION_LOADING, payload: false});
  //       this.toastr.error(this.translate.instant('toastrMessage.error.currentPassword'));
  //     });
  //   }
  // }

  openModal(change?) {
    this.needChange = change;
    this.modalRef = this.modalService.open(this.bodyContent, { backdrop: 'static', keyboard: false, centered: true });
    this.initForm();
  }

  closeModal() {
    if (!this.needChange) {
      this.initForm();
    } else {
      this.authService.doLogout();
    }
    this.modalRef.close();
  }

  valid() {
    if (!this.form.valid) {
      for (const control in this.form.controls) {
        if (this.form.controls.hasOwnProperty(control)) {
          this.form.controls[control].markAsTouched();
        }
      }
      return false;
    }
    return true;
  }
}
