import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Constants } from '../shared/constants/constants';
import { isNullOrUndefined } from 'util';

@Injectable()
export class IsAuthenticated implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    public constants: Constants,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.afAuth.authState.pipe(map(auth => {
      if (isNullOrUndefined(auth)) {
        this.router.navigate(['/authentication/login']);
        return false;
      }

      const user = this.authService.getUser();
      if (user && user.buildings && user.buildings.length > 0) {
        const buildings = user.buildings;

        if (buildings[0].status !== this.constants.ACTIVE_STATUS && !user.superUser) {
          this.authService.doLogoutAsync();

          this.toastr.error(this.translate.instant('toastrMessage.error.userInactive'));
          this.router.navigate(['/authentication/login']);
          return false;
        }

        if (buildings[0].userStatus !== this.constants.ACTIVE_STATUS && !user.superUser) {
          this.authService.doLogoutAsync();

          this.toastr.error(this.translate.instant('toastrMessage.error.userInactive'));
          this.router.navigate(['/authentication/login']);
          return false;
        }

        return true;
      } else {
        return true;
      }
    }));
  }
}
