import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dinamyc-link',
  templateUrl: './dinamyc-link.component.html',
  styleUrls: ['./dinamyc-link.component.scss']
})
export class DinamycLinkComponent implements OnInit {
  
type:string = "";
token:string = "";
sid:string = null;

  constructor(private route : ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private dataService: DataService) { 
  	this.route.queryParams.subscribe((params:any)=>{
  		this.type = params.type || this.type;
   		this.token = params.token || this.token;
      this.sid = params.sid || this.sid;

      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
  	});
  }

  ngOnInit(): void {
    this.dataService.getLoading().subscribe((state) => {
      this.translate.use(state.curLanguage);
    });
  }

}
