import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { ACTION_LOADING } from '../../component/store/actions/actions';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Output() onConfirm = new EventEmitter();

  @ViewChild('content', {static: false}) bodyContent: ElementRef;

  modalRef: any;
  form: FormGroup;
  loading = false;
  labelText = 'modal.select';
  imgDefault = 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon_empty_camera.svg';
  image = this.imgDefault;
  selected = false;
  picture: string;
  format: string;
  data: any;

  separateDialCode: boolean = true;
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Panama, CountryISO.Colombia];

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private translate: TranslateService,
    private commonService:CommonService,
  ) {
    this.userService.getUserProfile().subscribe((resp: any) => {
      this.data = resp.data;
      this.onConfirm.emit(this.data);
      },
      (error) => {
        this.toastr.error(this.translate.instant('toastrMessage.error.errorLoadProfile'));
      },
    )
  }

  ngOnInit() {}

  initForm(data?) {
    this.form = this.fb.group({
      id: this.fb.control(data && data.id ? data.id : null),
      uid: this.fb.control(data && data.id ? data.id : null),
      name: this.fb.control(data && data.name ? data.name : null),
      dni: this.fb.control(data && data.dni ? data.dni : null),
      phone: this.fb.control(data && data.phoneDetail ? data.phoneDetail.nationalNumber : null),
      email: this.fb.control({ value: data && data.email ? data.email : null, disabled: true }),
      photoUrl: this.fb.control(data && data.photoUrl ? data.photoUrl : null),
    });
    this.labelText = 'modal.select';
    this.image = data && data.photoUrl ? data.photoUrl : this.imgDefault;
    this.countryISO = data && data.phoneDetail ? data.phoneDetail.countryCode : null;
  }

  onSubmit() {
    this.commonService.validPhoneExceptions(this.form.controls.phone);
    if (this.valid()) {
      this.userService.isLoading({ action: ACTION_LOADING, payload: true });
      const user = this.form.getRawValue();
      const lUser = this.userService.getUser();
      if (this.data.email === user.email && this.data.name === user.name && this.data.phone === user.phone) {
        this.userService.isLoading({ action: ACTION_LOADING, payload: false });
        this.closeModal();
      }
      if (this.data.name !== user.name || this.data.phone !== user.phone.e164Number) {
        this.userService.updateAdminInfo(user.name, user.phone).subscribe(
          (resp: any) => {
            this.data.name = resp.data.name;
            this.data.phone = resp.data.phone;
            this.data.phoneDetail = resp.data.phoneDetail;
            this.onConfirm.emit(this.data);
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.closeModal();
            this.toastr.success(this.translate.instant('toastrMessage.success.profileUpdated'));
          },
          (error) => {
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.toastr.error(this.translate.instant('toastrMessage.error.update'));
          },
        );
      }
      if (this.picture) {
        this.userService.uploadPicture(this.picture, this.format).subscribe(
          (resp: any) => {
            this.data.photoUrl = resp.data.photoUrl;
            this.onConfirm.emit(this.data);
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.closeModal();
            this.selected = false;
            this.toastr.success(this.translate.instant('toastrMessage.success.profileUpdated'));
          },
          (error) => {
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.toastr.error(this.translate.instant('toastrMessage.error.update'));
          },
        );
      }
      if (this.data.email !== user.email) {
        this.userService.updateAdminEmail(user.email).subscribe(
          (resp: any) => {
            this.data.email = resp.data.email;
            this.onConfirm.emit(this.data);
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.closeModal();
            this.toastr.success(this.translate.instant('toastrMessage.success.profileUpdated'));
          },
          (error) => {
            this.userService.isLoading({ action: ACTION_LOADING, payload: false });
            this.toastr.error(this.translate.instant('toastrMessage.error.update'));
          },
        );
      }
      this.userService.isLoading({ action: ACTION_LOADING, payload: false });
    }
  }

  openModal() {
    this.modalRef = this.modalService.open(this.bodyContent, { backdrop: 'static', keyboard: false, centered: true, size: 'lg' });
    this.initForm(this.data);
  }

  closeModal() {
    this.modalRef.close();
    this.initForm();
  }

  valid() {
    if (!this.form.valid) {
      if (this.form.controls.phone.dirty && (this.form.controls.phone.errors && this.form.controls.phone.errors.validatePhoneNumber.valid == false)) {
        this.toastr.error(this.translate.instant('toastrMessage.error.numberInvalid'));
        return false;
      }

      for (const control in this.form.controls) {
        if (this.form.controls.hasOwnProperty(control)) {
          this.form.controls[control].markAsTouched();
        }
      }
      return false;
    }
    return true;
  }

  changePicture(e) {
    const file = e.target.files[0];
    this.format = file.type.split("/")[1]
    if (file) {
      if (!file.type.includes('image/')) {
        this.toastr.error(this.translate.instant('toastrMessage.error.selectFile'));
      } else {
        if (["png", "jpg", "jpeg"].includes(this.format)) {
          if (typeof FileReader === 'function') {
            const reader: FileReader = new FileReader();
            reader.onload = (event: any) => {
              this.picture = event.target.result;
              this.image = event.target.result;
              this.labelText = file.name;
              this.selected = true;
            };
            reader.readAsDataURL(file);
          } else {
            this.toastr.error(this.translate.instant('toastrMessage.error.update'));
          }
        } else {
          this.toastr.error(this.translate.instant('toastrMessage.error.error_notice_type_document'));
        }
      }
    } else {
      this.image = this.imgDefault;
      this.labelText = 'modal.select';
      this.selected = false;
      this.picture = null;
    }
  }

  removeImage() {
    this.image = this.imgDefault;
    this.labelText = 'modal.select';
    this.selected = false;
  }
}
