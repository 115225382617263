export enum AmplitudeEvent {
  qrCodeDownloaded = '[Web Administrator] QR Code Downloaded',
  homeViewed = '[Web Administrator] Home Viewed',
  accountStatusStarted = '[Web Administrator] Account Status Started',
  paymentMethodsAdded = '[Web Administrator] Payment Methods Added',
  expirationDateAdded = '[Web Administrator] Expiration Date Added',
  customerIdImported = '[Web Administrator] Customer ID Imported',
  unitsLinked = '[Web Administrator] Units Linked',
  customerLedgersImported = '[Web Administrator] Customer Ledgers Imported',
  activationEnded = '[Web Administrator] Activation Ended',
  accountStatusViewed = '[Web Administrator] Account Status Viewed',
  fileImported = '[Web Administrator] File Imported',
  paymentRequired = '[Web Administrator] Payment Required',
  communicationSelected = '[Web SuperAdmin] Communication Selected',
  imagesCommunicationsUpload = '[Web SuperAdmin] Images Communications Upload',
  multisessionsCreated = '[Web SuperAdmin] Multisessions created',
  noticesSelected = '[Web Administrator] Notices Selected',
  reservationSelected = '[Web Administrator] Reservation Selected',
  communicationsSended = '[Web SuperAdmin] Communications Sended',
  newNoticedCreated = '[Web Administrator] New Noticed Created',
  reservationCanceled = '[Web Administrator] Reservation Canceled',
  usersExcelDownloaded = '[Web Administrator] Excel Downloaded',
  accountingSystemSelected = '[Web Administrator] Accounting System Selected',
  unitCreated = '[Web Administrator] Unit Created',
  selectedNoticeMacroCommunity = '[Web Administrator] Macro Communities Notices Selected',
  createdNoticeMacroCommunity = '[Web Administrator] Macro Communities New Noticed Created',
  pqrsActivated = '[Web SuperAdmin] PQRS Activated',
  pqrsVisualized = '[Web Administrator] PQRS Visualized',
  pqrsStarted = '[Web Administrator] PQRS Started',
  pqrsUserReporterSelected = '[Web Administrator] User PQRS Reporter Selected',
  pqrsCreated = '[Web Administrator] PQRS Created',
  pqrsSettingsOpened = '[Web Administrator] PQRS Settings Opened',
  pqrsSettingsSaved = '[Web Administrator] PQRS Settings Saved',
  pqrsStatus = '[Web Administrator] PQRS Status',
  frequentAccessQrCodeActivated = '[Web SuperAdmin] Frecuent Access QR code Activated',
  frequentAccessAdminStarted = '[Web Administrator] Frecuent Access Admin Started',
  frequentAccessAdminCreated = '[Web Administrator] Frecuent Access Admin Created',
  uniqueAccessAdminStarted = '[Web Administrator] Unique Access Admin Started',
  uniqueAccessAdminCreated = '[Web Administrator] Unique Access Admin Created',
  uniqueAccessAdminDeleted = "[Web Administrator] Unique Access Admin Delete",
  uniqueVehicleCreated = '[Web Administrator]  Unique Vehicle Registration Created',
  frequentAccessAdminModified = '[Web Administrator] Frecuent Access Admin Modified',
  frequentAccessAdminDeleted = '[Web Administrator] Frecuent Access Admin Deleted',
  frequentVehicleCreated = '[Web Administrator]  Frequent Vehicle Registration Created',
  timeExtendedVisit = '[Web SuperAdmin] Time Extended',
  kioskoCreatedRole = '[Web SuperAdmin] Kiosk Created',
  buildingCreated = '[Web SuperAdmin] Building Created',
  callsViewed = '[Web Admin] Call Consulted',
  callAudioDonwloaded = '[Web Admin] Audio Downloaded',
  callsReportDownloaded = '[Web Admin] Call Report Downloaded ',
  agoraUserPreregistration = "[Web Admin] User preregistration Agora Triggered",
  agoraUserCreation = "[Web Admin] User Created Agora Triggered",
  agoraWhatsappSent = "[Web Admin] Whatsapp Agora Triggered"
}

export class AmplitudeGenericEvent {
  event_type: AmplitudeEvent;
  user_id: string;
  event_properties?:
    | CustomerLedgersImported
    | FileImported
    | CustomerIdImported
    | PaymentRequired
    | QrCodeDownloaded
    | CommunicationsSended
    | NewNoticedCreated
    | NewNoticedCreated
    | ReservationSelected
    | CommunicationSelected
    | NoticesSelected
    | CreatedNoticeMacroCommunity
    | MultisessionsCreated
    | AccountingSystemSelected
    | CreatedNoticeMacroCommunity
    | CheckBoxEnabled
    | StatusChangedPQRS
    | UserRol
    | createPQRS
    | extendVisitTime
    | createdKioskoRol
    | BuildingCreated;
}

export class CustomerLedgersImported {
  'Import State': ImportStatus;
}

export class FileImported {
  'Import State': ImportStatus;
}

export class CustomerIdImported {
  'Import State': ImportStatus;
}

export enum ImportStatus {
  successful = 'Successful',
  failed = 'Failed',
}

export class PaymentRequired {
  'Users Up to Day': string;
  'Debtors 1 To 30 Days': string;
  'Debtors 30 To 60 Days': string;
  'Debtors 60 Days': string;
}

export class QrCodeDownloaded {
  'Download State': DownloadStatus;
}

export enum DownloadStatus {
  successful = 'true',
  failed = 'false',
}

export class CommunicationSelected {
  'Action Communications Type': ActionCommunicationsType;
}

export enum ActionCommunicationsType {
  new = 'New',
  modify = 'Modify',
  delete = 'Delete',
}

export class MultisessionsCreated {
  'PH Name': string;
}

export class CommunicationsSended {
  'Type Notification Communications': TypeNotification;
  'Subject Communications': string;
}

export enum TypeNotification {
  both = 'Both',
  email = 'Email',
  notificationPush = 'Notification Push',
}

export class NewNoticedCreated {
  'User Notices Selected': String;
}

export class ReservationSelected {
  'Response Admin Type': string;
}
export class NoticesSelected {
  'Action Notices Type': ActionNoticesType | string;
}

export enum ActionNoticesType {
  pdf = 'PDF',
}

export enum AccountingSystems{
  sage50 = "Sage50",
  other = "Other"
}

export class AccountingSystemSelected {
  'System Type' : AccountingSystems;
}

export class CreatedNoticeMacroCommunity {
  'Subject News': string;
  'Group Name': string;
}
export class CheckBoxEnabled {
  'Checkbox Enabled': boolean;
}

export enum StatusPQRS {
  pending = 'Pendiente',
  inProgress = 'En Curso',
  expired = 'Vencida',
  solved = 'Resuelta',
}

export class StatusChangedPQRS {
  'PQRS status': StatusPQRS;
}

export class UserRol {
  'User rol': string;
}

export enum categoriesPQRS {
  request = 'Petición',
  claim = 'Reclamo',
  complain = 'Queja',
  suggestion = 'Sugerencia',
}

export class createPQRS {
  'PQRS Category': categoriesPQRS;
  'PQRS Title': string;
}

export class extendVisitTime {
  'Subject Time Extended': string
}

export class createdKioskoRol {
  'Name Kiosk': string
}

export class BuildingCreated {
  'PH Name': string;
  'Creation Date': string;
}
