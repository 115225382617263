import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
  // Status
  INACTIVE_STATUS: string = 'inactive';
  ACTIVE_STATUS: string = 'active';

  // Roles
  SUPERADMIN_ROLE: string = 'superAdmin';

  // Graphics
  labelMonths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  labelRegistered = ['Usuarios no ingresados', 'Usuarios ingresados'];
  labelActivity = [
    'Visitas registradas',
    'Delivery registrados',
    'Visitas registradas por residentes',
    'Invitaciones aprobadas',
    'Áreas reservadas',
    'Avisos vistos',
    'Mensajes enviados',
  ];

  lineOption = {
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            steps: 10,
            max: 100,
          },
        },
      ],
    },
  };

  lineOptionNoLegend = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            steps: 10,
            max: 100,
          },
        },
      ],
    },
  };

  donutOption = {
    legend: {
      display: true,
      position: 'bottom',
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return '';
            },
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              return '';
            },
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
  };

  graphicsOption = {
    legend: {
      display: true,
      position: 'bottom',
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  graphicsOptionNoLegend = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  
  providerApiWhatsapp = 'https://api.whatsapp.com/send?'
}


export enum Languaje {
  es = "es",
  en = "en"
}

export enum LanguajeLocale {
  es = "es-ES",
  en = "en-US"
}