import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ACTION_LOADING } from '../../component/store/actions/actions';
import { Router } from '@angular/router';
//SERVICES
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';

enum authModals{ login = 'login', logout = 'logout'};
@Component({
  selector: 'app-multisession-sidebar',
  templateUrl: './multisession-sidebar.component.html',
  styleUrls: ['./multisession-sidebar.component.scss']
})
export class MultisessionSidebarComponent implements OnInit {
@ViewChild('loginModal', { static: true }) loginModal: ElementRef;
@ViewChild('logoutModal', { static: true }) logoutModal: ElementRef;

alphabet:string[] = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','ñ','o','p','q','r','s','t','u','v','w','x','y','z'];
filterBy:string[] = [];
sort:string = 'desc';
sessions:any[];
orderedSessions:any;
currentSession:any;
sessionSelected:any;
showSideBar:boolean = false;
slideIn:boolean = false;
modalRef: any;
search:string = null;
checkboxControl:any = {};


  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private modalService: NgbModal,
    private dataService: DataService
  ){
    this.sessions = this.dataService.getUserInMultisession() ?? [];
    this.sessions.sort((a,b)=> a.name.charAt(0).localeCompare(b.name.charAt(0)));
    this.currentSession = this.dataService.getUser();
  }

  ngOnInit(): void {
    this.groupAccountsByLetter();
    this.initCheckbox();
  }

  initCheckbox(){
    this.alphabet.map((l)=> this.checkboxControl[l] = false);
  }

  changeCurrentUser(user){
    this.dataService.setUser(user);
    this.toastr.success(this.translateService.instant('toastrMessage.success.accountChanged'));
    const macroCommunity = user.buildings && user.buildings[0].config ? user.buildings[0].config.macrocommunity : null;
    if (macroCommunity && macroCommunity.enable && macroCommunity.communities[0] && macroCommunity.communities[0].type === 'macrocommunity') {
      this.router.navigate(['/app-community']);
    } else {
      if(user.roles.includes('admin')){
        this.router.navigate(['/app/dashboard']);
      }else if(user.roles.includes('superAdmin')){
        this.router.navigate(['/app/dashboard-admin']);
      }
    }
    window.location.reload();
  }

  check(letter:string){
    let foundIn = this.filterBy.findIndex((l:string) => l == letter);
    if(foundIn == -1){
      this.filterBy.push(letter);
      this.filterBy.sort((a,b)=> a.localeCompare(b));
    }else{
      this.filterBy.splice(foundIn, 1);
    }
  }

  groupAccountsByLetter(){
    let groups:any = new Object();
    for(let s of this.sessions){
      this.alphabet.forEach((l:string) =>{
        if(s.name.charAt(0).toLowerCase() === l){
          groups[l] ? groups[l].push(s) : groups[l] = [s];
        }
      });
    }
    this.orderedSessions = null;
    this.orderedSessions = {...groups};
  }

  openModal(modalClass:string, session:any): void {
    if(session) this.sessionSelected = session;
    let showModal = modalClass === authModals.login ? this.loginModal : this.logoutModal;
    this.modalRef = this.modalService.open(showModal, {
      windowClass: modalClass + '-modal',
      backdropClass: 'blur-backdrop',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'md',
    });
  }

  closeModal(e:any): void {
    this.modalRef.close();
    if(e.logged) this.changeView();
    if(e.logout){
      this.sessions = e.sessions;
      localStorage.setItem('usersSessions', JSON.stringify(e.sessions));
      this.groupAccountsByLetter();
      this.toastr.success(this.translateService.instant('toastrMessage.success.accountClosed'));
    }
  }

  changeView(){
    switch (this.slideIn) {
      case true:
        this.slideIn = false;
        setTimeout(() => { this.showSideBar = false }, 500);
      break;
      case false:
        this.showSideBar = true;
        setTimeout(() => { this.slideIn = true }, 100);
      break;
    }
  }

  restoreFilters(){
    this.filterBy = [];
    this.initCheckbox();
  }
}
