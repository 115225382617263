<ng-template #contentDownload let-d="dismiss">
	<div class="modal-body">
		<div class="first-section">
			<img class="" [src]="icon" height="116px" width="116px" *ngIf="icon !== ''">
			<div *ngIf="success">
				<h1 *ngIf="text !== ''">{{text}}</h1>
			</div>
			<div *ngIf="!success">
				<h1 class="m-0 mr-2">{{text[0]}}<img class="emoji" [src]="emoji" *ngIf=emoji></h1>
				<h1 class="mt-0">{{text[1]}}</h1>
			</div>
		</div>
		<div class="hr"></div>
		<div class="second-section text-center" *ngIf="dynamicLink">
			<p>Descarga el app e ingresa a tu cuenta</p>
			<a class="btn btn-primary" [href]="dynamicLink">INGRESA</a>
		</div>
	</div>
</ng-template>