import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-cancel-change',
  templateUrl: './cancel-change.component.html',
  styleUrls: ['./cancel-change.component.css']
})
export class CancelChangeComponent implements OnInit, AfterViewInit {
@ViewChild('contentCancel') templateRef: TemplateRef<any>;
userId : string = '';
token : string = '';
newBuildingSid : string = '';
buildingName : string = '';
success:boolean = true;

  constructor(private modalService: NgbModal,
    private publicService : PublicService,
    private route : ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private dataService: DataService) {

    this.route.queryParams.subscribe((params:any)=>{
      this.userId = params.userId ? params.userId : this.userId;
      this.token = params.token ? params.token : this.token;
      this.newBuildingSid = params.newBuildingSid ? params.newBuildingSid : this.newBuildingSid,
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
    });
}

  ngOnInit(): void {
    this.dataService.getLoading().subscribe((state) => {
      this.translate.use(state.curLanguage);
    });
    this.getSidName();
    this.cancelCommunityChange();
  }

  ngAfterViewInit(){
    this.openDownload(this.templateRef);
  }

  getSidName() {
    if ( !this.newBuildingSid  || this.newBuildingSid === '') {
      return;
    }
    this.publicService.getNameSid( this.newBuildingSid )
    .subscribe(
      (res: any) => {
        this.buildingName = res.data.building.name
      },
      err => {
        console.error(err)
      }
    )
  }
 
  openDownload(content) {
    this.modalService.open(content, { windowClass: 'download-app-modal', size: 'md', backdrop:"static", keyboard: false }).result.then((result) => {
    }, (reason) => {});
    setTimeout(()=> {
      let modal = <HTMLElement> document.querySelector(".download-app-modal");
      modal.style.height = "-webkit-fill-available";
      modal.style.display = "flex";
      modal.style.alignItems = "center";
    },1) 
    return false;
  }

  cancelCommunityChange() {
    if (!this.userId && !this.token && this.userId === '' && this.token ==='') {
      return;
    }
    this.publicService.cancelCommunityChange(this.userId, this.token)
    .subscribe(
      (res: any) => {
        this.success = true;
      },
      err => {
        this.success = false;
        console.error(err)
      }
    )
  }
}
