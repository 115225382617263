import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { datadogRum } from '@datadog/browser-rum';
import "rxjs/Rx";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogConfig } from 'datadog.config';
const { version } = require('../version-config');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));


datadogRum.init({
  applicationId: datadogConfig.applicationId,
  clientToken: datadogConfig.clientToken,
  site: datadogConfig.site,
  service: datadogConfig.service,
  env: environment.envDeployed,
  // Specify a version number to identify the deployed version of your application in Datadog 
  version: version,
  sessionSampleRate: 5,
  sessionReplaySampleRate: 5,
  trackUserInteractions: true,
  trackResources: false,
  trackLongTasks: false,
  defaultPrivacyLevel:'mask-user-input',
  beforeSend: (rumEvent) => { // Agrega esta función para personalizar los datos enviados en cada evento RUM
    if(rumEvent.action) rumEvent.view.url = rumEvent.view.url.replace('#', '')
    if (rumEvent.type === 'error') {
      // Agrega información adicional a cada evento de error
      rumEvent.context.custom = {
        route: window.location.hash, // Captura la ruta actual de la página en la que se produjo el error
        userAgent: navigator.userAgent // Captura el agente de usuario del navegador
      };
    }
  }
});
    
datadogRum.startSessionReplayRecording();