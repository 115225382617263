<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- First level menu -->
    <li
      class="sidebar-item"
      [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{ sidebarnavItem.title }}</div>
      <!-- External Routes -->
      <a *ngIf="sidebarnavItem.externalLink" class="sidebar-link waves-effect waves-dark" [ngClass]="{'nuevo' : (sidebarnavItem.new && currentLanguage === 'es'), 'new' : currentLanguage === 'en'}" [href]="sidebarnavItem.path" target="_blank">
        <i *ngIf="!sidebarnavItem.icon2" [ngClass]="[sidebarnavItem.icon]"></i>
        <img class="icon" *ngIf="sidebarnavItem.icon2" [src]="sidebarnavItem.icon2" height="25" width="21">
        &nbsp;<span class="hide-menu">{{ sidebarnavItem.title | translate }}</span>
      </a>
      <!-- Internal Routes -->
      <a
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
        [ngClass]="[sidebarnavItem.class]"
        [ngClass]="{'nuevo' : (sidebarnavItem.new && currentLanguage === 'es'), 'new' : (sidebarnavItem.new && currentLanguage === 'en')}"
        *ngIf="!sidebarnavItem.extralink && !sidebarnavItem.externalLink"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
        <i *ngIf="!sidebarnavItem.icon2" [ngClass]="[sidebarnavItem.icon]"></i>
        <img class="icon" *ngIf="sidebarnavItem.icon2" [src]="sidebarnavItem.icon2" height="25" width="21">
        &nbsp;<span class="hide-menu">{{ sidebarnavItem.title | translate }}</span>
      </a>
      <!-- Second level menu -->
      <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{ in: showMenu === sidebarnavItem.title }">
        <li
          class="sidebar-item"
          *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
          [class.active]="showSubMenu === sidebarnavSubItem.title"
          [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'"
        >
          <a
            class="sidebar-link"
            [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
            [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
            [ngClass]="[sidebarnavSubItem.class]"
            *ngIf="!sidebarnavSubItem.extralink"
            (click)="addActiveClass(sidebarnavSubItem.title)"
          >
            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
            &nbsp;<span class="hide-menu">{{ sidebarnavSubItem.title | translate }}</span>
          </a>
          <!-- Third level menu -->
          <ul
            aria-expanded="false"
            class="collapse Second-level"
            *ngIf="sidebarnavSubItem.submenu.length > 0"
            [ngClass]="{ in: showSubMenu === sidebarnavSubItem.title }"
          >
            <li
              class="sidebar-item"
              *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
              routerLinkActive="active"
              [ngClass]="[sidebarnavSubsubItem.class]"
            >
              <a
                class="sidebar-link"
                [routerLink]="[sidebarnavSubsubItem.path]"
                *ngIf="!sidebarnavSubsubItem.extralink"
                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'"
              >
                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                &nbsp;<span class="hide-menu">{{ sidebarnavSubsubItem.title | translate }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
