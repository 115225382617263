import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAccountancyCards, IUnitAccounting, TransactionsFilters, UnitsAccountingFilters } from '../interface/accountancy.interface';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService extends DataService {
  headers: HttpHeaders;

  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      // authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      // 'Fb-Token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  //Units calls
  getUnitsByBuilding(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/admin/v1/units/accountancy/${buildingId}`, { headers: this.headers });
  }

  getUnitsFromXls(form: FormData) {
    return this.http.post(`${environment.apiUrl}/super/v1/units/match-units`, form, { headers: this.headers });
  }

  getUnitsFormating(buildingId: string) {
    if (!this.headers.get('buildingId')) this.headers.append('buildingId', buildingId);
    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/matchedUnits`, { headers: this.headers });
  }

  //Transactions calls
  getTransactionsFromXls(form: FormData) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/transactions/verify`, form, { headers: this.headers });
  }

  getStatusConfig(buildingId: string) {
    // if (!this.headers || !this.headers.get('buildingId')) this.headers.append('buildingId', buildingId);
    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy`);
  }

  setLastTransactionUpdate(lastTransactionUpdate) {
    localStorage.setItem('lastTransactionUpdate', lastTransactionUpdate)
  }

  setOnboardingNotCompleted() {
    localStorage.setItem('onboardingNotCompleted', 'true')
  }

  removeOnboardingNotCompleted() {
    localStorage.removeItem('onboardingNotCompleted')
  }

  getOnboardingIsNotComplete() {
    return localStorage.getItem('onboardingNotCompleted')
  }

  setStatusConfigAccounting(statusConfig) {
    localStorage.setItem('statusConfigAccounting', JSON.stringify(statusConfig))
  }

  getStatusConfigAccounting() {
    return JSON.parse(localStorage.getItem('statusConfigAccounting'))
  }

  setStatusHeader(type: string) {
    localStorage.setItem('statusType', JSON.stringify(type));
  }

  getStatusHeader() {
    return JSON.parse(localStorage.getItem('statusType'))
  }

  setORemoveOnboardingStatus(statusConfig: any) {
    this.setStatusConfigAccounting(statusConfig)
    const stepper = statusConfig?.stepper
    const onboardingIsCompleted = stepper?.step == 4 && stepper?.status == "complete" ? true : false
    if (onboardingIsCompleted) this.removeOnboardingNotCompleted()
    else this.setOnboardingNotCompleted()
  }

  getLastTransactionUpdate() {
    return localStorage.getItem('lastTransactionUpdate')
  }

  updateStatusConfig(buildingId: string, config: any) {
    if (!this.headers.get('buildingId')) this.headers.append('buildingId', buildingId);
    let params = new HttpParams().set('i18n', this.getLanguage());
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy`, config, { headers: this.headers, params: params });
  }

  getTransactionsByBuilding(buildingId: string, query: any) {
    let params = new HttpParams().set('page', query.page).set('size', query.size);
    if (query.unitId) params = params.set('unitId', query.unitId);
    if (query.sortField) params = params.set('sortField', query.sortField);
    if (query.sortOrder) params = params.set('sortOrder', query.sortOrder);
    if (query.dateStart) params = params.set('dateStart', query.dateStart);
    if (query.dateEnd) params = params.set('dateEnd', query.dateEnd);

    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/transactions/${buildingId}`, { headers: this.headers, params: params });
  }

  getUnitsAccountancyByBuilding(buildingId: string, query: any) {
    let params = new HttpParams().set('page', query.page).set('size', query.size);
    if (query.unitId) params = params.set('unitId', query.unitId);
    if (query.sortField) params = params.set('sortField', query.sortField);
    if (query.sortOrder) params = params.set('sortOrder', query.sortOrder);
    if (query.dateStart) params = params.set('dateStart', query.dateStart);
    if (query.dateEnd) params = params.set('dateEnd', query.dateEnd);
    if (query.expirationDays) params = params.set('expirationDays', query.expirationDays);
    if (query.status) params = params.set('status', query.status);

    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/units-account-statements/${buildingId}`, { headers: this.headers, params: params });
  }

  getUnitsIdByBuilding(buildingId: string, query: any) {
    let params = new HttpParams().set('expirationDays', query.expirationDays);
    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/units-account-statements/${buildingId}/all`, { headers: this.headers, params: params });
  }

  getTransactionsIdByBuilding(buildingId: string, query: any) {
    let params = new HttpParams();
    if (query.unitId) params = params.set('unitId', query.unitId);
    if (query.dateStart) params = params.set('dateStart', query.dateStart);
    if (query.dateEnd) params = params.set('dateEnd', query.dateEnd);
    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/transactions/${buildingId}/all`, { headers: this.headers, params: params });
  }

  getTransactionFilesHistory(buildingId: string, query: any) {
    let params = new HttpParams().set('page', query.page).set('size', query.size);

    if (!this.headers.get('buildingId')) this.headers.append('buildingId', buildingId);
    if (query.dateStart) params = params.set('dateStart', query.dateStart);
    if (query.dateEnd) params = params.set('dateEnd', query.dateEnd);
    return this.http.get(`${environment.apiUrl}/admin/v1/accountancy/transactions-history`, { headers: this.headers, params: params });
  }

  deleteTransactionFilesHistory(file) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/transactions-history/delete`, file);
  }

  cancelTransactionImport(fileId: string) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/transactions-history/cancel`, { fileId: fileId }, { headers: this.headers });
  }

  getAccountStatementByCards(): Observable<IAccountancyCards> {
    return this.http.get<IAccountancyCards>(`${environment.apiUrl}/admin/v1/accountancy/cards`, { headers: this.headers });
  }

  exportTransactions(buildingId: string, filters: TransactionsFilters) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/transactions/${buildingId}/pdf`, filters, { headers: this.headers });
  }

  exportCollectionsManagement(buildingId: string, filters: UnitsAccountingFilters) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/collections-management/${buildingId}/pdf`, filters, { headers: this.headers });
  }

  sendNotificacionCollectionsManagement(accounting: IUnitAccounting[]) {
    return this.http.post(`${environment.apiUrl}/admin/v1/accountancy/collections-management`, { unitsId: accounting, i18n: this.getLanguage() }, { headers: this.headers });
  }

}