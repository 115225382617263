<div class="logout-modal">
  <div class="icon">
    <img *ngIf="allSessions" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/power-button.png" alt="logout">
    <img *ngIf="!allSessions" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/logout-1.png" alt="logout">
  </div>
  <h2 class="title" *ngIf="allSessions">{{ 'authentication.modal.logoutAll' | translate }}</h2>
  <h2 class="title" *ngIf="!allSessions">{{ 'authentication.modal.logoutConfirm' | translate : {userName: session.name} }}</h2>
  <p class="description" *ngIf="allSessions">{{ 'authentication.modal.logoutAllConfirm' | translate }}</p>
  <section class="buttons">
    <button class="btn btn-cancel" (click)="closeModal({logout:false})">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn btn-next" (click)="closeSession()">{{ 'buttons.accept' | translate }}</button>
  </section>
</div>
