<div class="emptyState1">
    <div class="emptyState1__container">
      <div>
        <img [src]="empty.img" alt="">
      </div>
      <div class="mt-4">
        <h3 class="houstonText">{{ empty.title | translate }}</h3>
      </div>
      <br>
      <div class="houstonText">
        <h3>{{ empty.subtitle | translate }}</h3>
      </div>
    </div>
</div>