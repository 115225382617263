import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class PlanService extends DataService {
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  fetchPlans() {
    return this.http
      .get(`${environment.apiUrl}/plan`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
}
