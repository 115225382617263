import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterUserPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    let array = [];
    if (arg === '' || !arg) return value;
    for (const post of value) {
      if (post.name.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        array.push(post);
      }
    }
    return array;
  }
}
