import { isArray } from 'lodash';
import { Injectable } from '@angular/core';

Injectable();
export class ErrorsDashboard {
  constructor() {}

  LOGIN_ERROR = {
    'auth/user-not-found': 'toastrMessage.error.userNotFound',
    'auth/wrong-password': 'toastrMessage.error.wrongPassword',
  };
}

export function errorMessage(object: any) {
  if (!object) return null;

  if (isArray(object)) {
    console.log('Es arreglo!');
  }
}
