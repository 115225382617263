import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `<div class="prelisLoading">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>`,
  encapsulation: ViewEncapsulation.None,
  host: { '[class.isActive]': 'isLoading' },
})
export class LoadingComponent {
  @Input() public isLoading = false;

  constructor() {}
}
