import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { PublicRoutingModule } from './public-routing.module';
import { DinamycLinkComponent } from './dinamyc-link/dinamyc-link.component';
import { ModalDownloadAppComponent } from "./modal-download-app/modal-download-app.component";
import { ModalNotificationComponent } from './modal-notification/modal-notification.component';
//services
import { PublicService } from '../services/public.service';
import { BuildingSidComponent } from './building-sid/building-sid.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CancelChangeComponent } from './cancel-change/cancel-change.component';
import { CreateUserComponent } from './create-user/create-user.component';
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
  // return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [DinamycLinkComponent, ModalDownloadAppComponent, ModalNotificationComponent, BuildingSidComponent, CancelChangeComponent, CreateUserComponent],
  imports: [
    CommonModule,
    PublicRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    })
  ],
  providers:[PublicService]
})
export class PublicModule { }
