import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable()
export class Roles implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.authService.getUser();
    if (!user) {
      this.router.navigate(['/authentication/login']);
      return false;
    }
    const isAdmin = user.roles.indexOf('admin') > -1;
    const isFintechAdmin = user.roles.indexOf('fintech-admin') > -1;
    const isVigilant = user.roles.indexOf('vigilant') > -1;
    const isTenant = user.roles.indexOf('tenant') > -1;
    // const isSuperAdmin = user.roles.indexOf('superAdmin') > -1;
    const isProprietor = user.roles.indexOf('proprietor') > -1;
    const isCommittee = user.roles.indexOf('committee') > -1;
    const isCustomized = user.roles.indexOf('customized') > -1;
    const inInvitations = state.url === '/app/invitations';
    const inHome = state.url === '/app';

    if (isAdmin) {
      // const isMacroCommunity = this.authService.getUserIsMacroCommunity();
      const isMacroCommunityFather = this.authService.getBuildingIsMacroFather();
      if (isMacroCommunityFather) {
        let regexMacroRoutes = /\/app-community/i;
        if ( regexMacroRoutes.test(state.url)) return true;
        this.router.navigate(['/app-community']);
        return false;
      }
      return true;
    }

    // if (isSuperAdmin) return true;

    if (isVigilant) {
      if (inInvitations) return true;

      //this.router.navigate(['/app/invitations']);
      this.router.navigate(['/authentication/login']);
      return false;
    }

    if (isTenant) {
      if (inHome) return true;

      // this.router.navigate(['/app/dashboard']);
      this.router.navigate(['/authentication/login']);
      return false;
    }

    if (isProprietor) {
      this.router.navigate(['/authentication/login']);
      return false;
    }

    if (isCommittee) {
      this.router.navigate(['/authentication/login']);
      return false;
    }

      if(isCustomized){
      //Find first path available for user
      let path = this.authService.getUserFirstRoute(user)
      this.router.createUrlTree([path]);
      return true;
    }
  }
}
