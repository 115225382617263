import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private http: HttpClient) { }

  invitationResponse(data: any, invitation_id: any) {
    return this.http.post(`${environment.apiUrl}/public/v1/invitations/${invitation_id}/update-status`, data);
  }

  notificationResponse(mailtype: any, token: any) {
    let params = new HttpParams()
      .append("token", token)
      .append("mailtype", mailtype);

    return this.http.get(`${environment.apiUrl}/public/v1/user/mail/unsubscribe`, { params: params });
  }

  getFastpass(type: any, token: any) {
    let params = new HttpParams()
      .append("token", token)
      .append("type", type);

    return this.http.get(`${environment.apiUrl}/public/v1/invitations/fast-pass`, { params: params });
  }

  getFrequentAccessData(token) {
    let params = new HttpParams()
      .append("token", token)
    return this.http.get(`${environment.apiUrl}/public/v1/frequent-access`, { params })
  }

  getNameSid(sid: string) {
    let params = new HttpParams().set("sid", sid);
    console.log(params)
    return this.http.get(`${environment.apiUrl}/public/v1/building/valid-sid`, { params: params }).pipe(
      tap(console.log)
    )
  }

  getGeneralConfig() {
    return this.http.get(`${environment.apiUrl}/public/v1/generalconfig`);
  }

  cancelCommunityChange(userId:any, token:any){
    return this.http.put(`${environment.apiUrl}/public/v1/user/${userId}/cancel-community`, { token: token });
  }
  
  getTypeCatalogs(query: any) {
    const params = new HttpParams().set('typeCatalog', query.typeCatalog);
    return this.http.get<any>(`${environment.apiUrl}/public/v1/catalogs`, { params });
  }

}
