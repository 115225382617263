<section class="payment mb-5 transactionsPage">

  <section *ngIf="accountancyCards != null">
    
    <div class="mini-card-1 w-100">
      <div class="row">
        
        <div class="col-md-10">
          <div class="row">

        <div class="col-md-6 percentageFilters" (click)="selectCard('unexpired')">
            <div class="dataFilter">
              <h4>{{ 'onboarding.managment.unexpired' | translate }} {{accountancyCards.unexpired.units}} {{ 'onboarding.managment.units' | translate | lowercase }} ({{ accountancyCards.unexpired.percentage }}%)</h4>
              <h4 class="ml-auto">{{ 'accounts-status.transactions.receivable' | translate}}:</h4>
            </div>
            <div class="dataFilter">
              <h4 class="ml-auto">{{ accountancyCards.unexpired.amount | currency:'USD':'symbol-narrow':'.0-2' }}</h4>
            </div>
          <div class="progressBar">
            <div class="progress">
              <div class="progress-bar progressBarGreen" role="progressbar" [style.width]="accountancyCards.unexpired.percentage + '%'" ></div>
            </div>
          </div>
        </div>

        <div class="col-md-6 percentageFilters" (click)="selectCard('expired30')">
          <div class="dataFilter">
            <h4>{{ 'onboarding.managment.expired30' | translate }} {{accountancyCards.expired1To30Days.units}} {{ 'onboarding.managment.units' | translate | lowercase }} ({{ accountancyCards.expired1To30Days.percentage }}%)</h4>
            <h4 class="ml-auto">{{ 'accounts-status.transactions.receivable' | translate}}:</h4>
          </div>
          <div class="dataFilter">
            <h4 class="ml-auto">{{ accountancyCards.expired1To30Days.amount | currency:'USD':'symbol-narrow':'.0-2' }}</h4>
          </div>
          <div class="progressBar">
            <div class="progress">
              <div class="progress-bar progressBarBlue" role="progressbar" [style.width]="accountancyCards.expired1To30Days.percentage + '%'" ></div>
            </div>
          </div>
        </div>

        <div class="col-md-6 percentageFilters" (click)="selectCard('expired60')">
          <div class="dataFilter">
            <h4>{{ 'onboarding.managment.expired60' | translate }} {{accountancyCards.expired30To60Days.units}} {{ 'onboarding.managment.units' | translate | lowercase }} ({{ accountancyCards.expired30To60Days.percentage }}%)</h4>
            <h4 class="ml-auto">{{ 'accounts-status.transactions.receivable' | translate}}:</h4>
          </div>
          <div class="dataFilter">
            <h4 class="ml-auto">{{ accountancyCards.expired30To60Days.amount | currency:'USD':'symbol-narrow':'.0-2' }}</h4>
          </div>
          <div class="progressBar">
            <div class="progress">
              <div class="progress-bar progressBarYellow" role="progressbar" [style.width]="accountancyCards.expired30To60Days.percentage + '%'" ></div>
            </div>
          </div>
        </div>

        <div class="col-md-6 percentageFilters" (click)="selectCard('expired60more')">
          <div class="dataFilter">
            <h4>{{ 'onboarding.managment.expired60+' | translate }} {{accountancyCards.expired60Days.units}} {{ 'onboarding.managment.units' | translate | lowercase }} ({{ accountancyCards.expired60Days.percentage }}%)</h4>
            <h4 class="ml-auto">{{ 'accounts-status.transactions.receivable' | translate}}:</h4>
          </div>
          <div class="dataFilter">
            <h4 class="ml-auto">{{ accountancyCards.expired60Days.amount | currency:'USD':'symbol-narrow':'.0-2' }}</h4>
          </div>
          <div class="progressBar">
            <div class="progress">
              <div class="progress-bar progressBarRed" role="progressbar" [style.width]="accountancyCards.expired60Days.percentage + '%'" ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="receivable mt-5">
        <h4 class=""><img src="https://munily-public-cdn.s3.amazonaws.com/accountingSystem/payments.png" alt=""></h4>
        <h4 class="">{{ receivable | currency:'USD':'symbol-narrow':'.0-2' }}</h4>
        <h4 class="ml-auto">{{ 'accounts-status.transactions.receivable' | translate}}</h4>
      </div>
    </div>

      </div>
    </div>
  </section>
  
  <section class="history-table" >
    <!-- <div (click)="selectAllItems()" class="select-all">
      <img id="selectAll" [src]="imageGeneral" alt="unchecked">
      <p>{{buttonSelect}}</p>
    </div> -->
    <div class="sub__header">
      <div class="sub__header-left">
        <div class="clickeables__filters--by-unit">
          <ng-select
            bindLabel="accountancy.accountingName"
            bindValue="_id"
            bindValue="number"
            [items]="units"
            (change)="emitNgSelectUnit($event)"
            placeholder="{{ 'onboarding.transactions.searchUnit' | translate }}"
            [closeOnSelect]="true"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              {{ item.accountancy.accountingName }}
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="sub__header-right">
        <div class="sub__header-center payment-footer__selected"  *ngIf="countsSelected > 0">
          <p>{{ 'onboarding.managment.paymentSelectUnits' | translate : { count: countsSelected } }}</p>
        </div>

        <button class="filter-button-collect" [disabled]="countsSelected === 0"  (click)="pay()">
          {{ 'onboarding.managment.collect' | translate }}
        </button>

        <ng-select bindLabel="name" class="pointer mx-2 select_edit" 
                  [placeholder]="'modal.button.filter' | translate" [(ngModel)]="selectOption" 
                  (change)="selectCard($event, 'selectSort')" 
                  [closeOnSelect]="true"
                  bindValue="filter"
                  >
          <ng-option
            *ngFor="let options of accountancyOptionsFilter"
            [value]="options.filter"
            >{{ options.name | translate}}</ng-option
          >
        </ng-select>

        <div class="clickeables__filters--more dropdown">
          <button class="btn dropdown__button-export"(click)="exportCollectionsManagement()">
            {{ 'onboarding.transactions.export' | translate }}
            <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_export_table.png" alt="" />
          </button>
        </div>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort multiTemplateDataRows (matSortChange)="sortCollections($event)" *ngIf="isVisibleDataTable" >
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="selectAllItems()" [checked]="stateSelectAllRow"></mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="selectUnit(element, dataSource.filteredData.indexOf(element))" [checked]="element.src"></mat-checkbox>
        </td>
      </ng-container>

      <!-- Unit Column -->
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by Unit" *matHeaderCellDef>
          <span> {{ 'onboarding.transactions.unitMunily' | translate }}</span>
        </th>

        <td mat-cell *matCellDef="let element">
          <!-- <img [src]="element.src" [id]="dataSource.filteredData.indexOf(element)" alt="unchecked" class="mr-2" /> -->
          {{ element.accountancy.accountingName }}
        </td>
      </ng-container>
      <!-- ExpiredDays Column -->
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by expiredDays" *matHeaderCellDef>
          <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_calendar_table.png" alt="calendar" />
          <span>{{ 'onboarding.transactions.daysOwen' | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div
            class="history-table__body-card"
            [ngStyle]="{
              'background-color': setAccountancyDays(element.accountancy.daysOwed).background,
              'border-color': setAccountancyDays(element.accountancy.daysOwed).borderColor
            }"
          >
           <span> {{ setAccountancyDays(element.accountancy.daysOwed).name | translate }}</span>
          </div>
        </td>
      </ng-container>
      <!-- ExpiredDebt Column -->
      <ng-container [matColumnDef]="displayedColumns[3]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by ExpiredDebt" *matHeaderCellDef>
          <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_symbol_table.png" alt="symbol" />
          <span>  {{ 'onboarding.transactions.OverdueAmount' | translate }} </span> 
        </th>
        <td mat-cell *matCellDef="let element">
          <p>{{ (element.accountancy.expiredDebt > 0 ? element.accountancy.expiredDebt : 0) | number : '.2-2' }}</p>
        </td>
      </ng-container>
      <!-- DebtToBeDue' Column -->
      <ng-container [matColumnDef]="displayedColumns[4]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by Debt to be Due" *matHeaderCellDef>
          <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_symbol_table.png" alt="symbol" />
          <span> {{ 'onboarding.transactions.amountDue' | translate }} </span> 
        </th>
        <td mat-cell *matCellDef="let element">
          <p>{{ (element.accountancy.debtDue ? element.accountancy.debtDue : 0) | number : '.2-2' }}</p>
        </td>
      </ng-container>
      <!-- TotalDebt Column -->
      <ng-container [matColumnDef]="displayedColumns[5]">
        <th mat-header-cell mat-sort-header sortActionDescription="Sort by Total Debt" *matHeaderCellDef>
          <img src="https://munily-public-cdn.s3.amazonaws.com/onboarding/dashboard/icons/Icons_symbol_table.png" alt="symbol" />
          <span>  {{ 'onboarding.transactions.totalDebt' | translate }} </span> 
        </th>
        <td mat-cell *matCellDef="let element">
          <p>{{ (element.accountancy.totalDebt ? element.accountancy.totalDebt : 0) | number : '.2-2' }}</p>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="displayedColumns[6]">
        <th mat-header-cell *matHeaderCellDef> {{ 'table.action' | translate }}</th>
        <td mat-cell *matCellDef="let element"  (click)="$event.stopPropagation()">
          <button class="btn" (click)="validationsPaySelectUnit(element)">
            {{ 'onboarding.managment.collect' | translate }}
          </button>
         </td>
      </ng-container>

      <!-- expand Column -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="cursor: pointer"
        (click)="selectUnit(row, dataSource.filteredData.indexOf(row))"
        [ngClass]="{ highlighted: isSelectRowTable(row.id) }"
      ></tr>
    </table>
    <div class="pagination" *ngIf="isVisibleDataTable">
      <mat-paginator [length]="pagination.totalDocs" [pageSize]="pagination.size" aria-label="Select page" showFirstLastButtons (page)="onPageEvent($event)">
      </mat-paginator>
    </div>
    <div *ngIf="!isVisibleDataTable">
      <app-empty-datatable [empty]="7"> </app-empty-datatable>
    </div>
  </section>
</section>

<!-- <section class="payment-footer" *ngIf="countsSelected > 0">
  <div class="payment-footer__selected">
    <p>{{ 'onboarding.managment.payment-footer' | translate : { count: countsSelected } }}</p>
  </div>
  <div class="payment-footer__button">
    <button (click)="pay()">{{ 'onboarding.managment.collect' | translate }}</button>
  </div>
</section> -->

<!-- <div *ngIf="!isVisibleDataTable" class="w-100">
  <app-empty-datatable [empty]="4"> </app-empty-datatable>
</div> -->

<ng-template #compatibility let-c="close" let-d="dismiss">
  <app-compatibility-modal [typeModal]="typeModal" [compatibilityModal]="compatibilityModal" (closeModal)="closeModal($event)"></app-compatibility-modal>
</ng-template>
