import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { DataService } from 'src/app/services/data.service';
import {
  TableSort,
  UserAdminMacro,
  UserCreatePayload,
  UserQueryParams,
  UserResponsePaginated,
  UserResponseRequest,
  UserResponseUserDelete,
  UserResponseUserUpdate,
  UserStatus,
} from '../../interfaces/administration/user.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuildingResponsePaginated, BuildingResponseRequest } from '../../interfaces/building.interface';

@Injectable()
export class UserAdministrationService extends DataService {
  headers: HttpHeaders;
  constructor(private httpClientUser: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).fbToken : null,
    });
  }

  getAllUsers(payload: UserQueryParams, query?: string): Observable<UserResponsePaginated> {
    const params = `page=${payload.page}&rowsPerPage=${payload.rowsPerPage}${query ? query : ''}`;

    return this.httpClientUser
      .get<UserResponseRequest>(`${environment.apiUrl}/admin-macro/v1/users?${params}`, { headers: this.headers })
      .pipe(map((resp) => resp.data));
  }

  getAllUsersId(query?: string) {
    const params = `${query ? query : ''}`;
    return this.httpClientUser
      .get<UserResponseRequest>(`${environment.apiUrl}/admin-macro/v1/users/all-simplified?${params}`, { headers: this.headers })
      .pipe(map((resp) => resp.data));
  }


  getUserById(userId: string): Observable<UserAdminMacro> {
    return this.httpClientUser.get<UserAdminMacro>(`${environment.apiUrl}/admin-macro/v1/users/${userId}`, { headers: this.headers });
  }

  createUser(payload: UserCreatePayload): Observable<UserAdminMacro> {
    return this.httpClientUser.post<UserAdminMacro>(`${environment.apiUrl}/admin-macro/v1/users`, payload, { headers: this.headers });
  }

  updateUser(userId: string, payload: UserAdminMacro): Observable<UserResponseUserUpdate> {
    return this.httpClientUser.put<UserResponseUserUpdate>(`${environment.apiUrl}/admin-macro/v1/users/${userId}`, payload, { headers: this.headers });
  }


  updateStatusUser(userId: string, payload: UserStatus): Observable<UserResponseUserUpdate> {
    return this.httpClientUser.patch<UserResponseUserUpdate>(
      `${environment.apiUrl}/admin-macro/v1/users/${userId}/status`,
      { status: payload },
      { headers: this.headers },
    );
  }

  deleteUser(userId: string): Observable<UserResponseUserDelete> {
    return this.httpClientUser.delete<UserResponseUserDelete>(`${environment.apiUrl}/admin-macro/v1/users/${userId}`, { headers: this.headers });
  }

  getAllUserGroups() {
    return this.httpClientUser.get(`${environment.apiUrl}/admin-macro/v1/buildings-users-group?all=true`, { headers: this.headers });
  }

  getUsersByGroupId(groupId?: string) {
    return this.httpClientUser.get(`${environment.apiUrl}/admin-macro/v1/buildings-users-group/${groupId}`, {
      headers: this.headers,
    });
  }

  createGroups(data: { name: string; users: string[] }) {
    let params = new HttpParams().set('i18n', this.getLanguage());
    return this.httpClientUser.post(`${environment.apiUrl}/admin-macro/v1/buildings-users-group`, data, { headers: this.headers, params });
  }

  updateGroups(data: { id: string; name: string; users: string[] }) {
    return this.httpClientUser.put(`${environment.apiUrl}/admin-macro/v1/buildings-users-group`, data, { headers: this.headers });
  }

  deleteGroup(groupId:string) {
    return this.httpClientUser.delete(`${environment.apiUrl}/admin-macro/v1/buildings-users-group`,{ body: {id: groupId}, headers: this.headers });
  }

  validatorGroup(name: string): Observable<any> {
    let params = new HttpParams().append('i18n', this.getLanguage()).append('groupName', name);

    return this.httpClientUser.get(`${environment.apiUrl}/admin-macro/v1/buildings-users-group/validator/name`, { headers: this.headers, params });
  } 

  addSortToQuery(query: string, sort: TableSort) {
    let newQuery = '';
    if (query.includes('sortField')) {
      newQuery = query.split('&sortField')[0];
    }

    query = newQuery + `&sortField=${sort.field}&sortOrder=${sort.order}`;
    return query;
  }
}
