export interface SidebarItem {
  path: string;
  title: string;
  icon: string;
  icon2: string;
  class: '';
  extralink: boolean;
  submenu: any[];
}

export const SIDEBAR_ITEM_NOTICE: SidebarItem = {
  path: '/app-community/notices',
  title: 'macroCommunity.notice.noticesPath',
  icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/icon-notice.svg',
  icon: 'fa fa-desktop fa-2x',
  class: '',
  extralink: false,
  submenu: [],
};
