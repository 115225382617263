import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTION_LOADING } from '../../../component/store/actions/actions';
import { FormGroup, FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
//Services
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { OnboardingService } from '../../../services/onboarding.service';
import { BuildingService } from '../../../services/building.service';
//Components
import { PaymentManagmentComponent } from '../payment-managment/payment-managment.component';
import { TransactionsComponent } from '../transactions/transactions.component';
//Interfaces
import { Steps } from 'src/app/interface/accountancy.interface';
//Others
import * as moment from 'moment-timezone';
import { ignoreElements } from 'rxjs/operators';



export enum AccountingButtons {
  paymentManagment = 'paymentManagment',
  updateAccounting = 'import',
  transactions = 'list',
  importedFiles = 'history',
  editDueDate = 'editDueDate',
  editUnit = 'editUnit',
  editPaymentMethod = 'editPaymentMethod',
  updateAccountingImport = 'accounts-status',
  button = 'button'
}

// See the Moment.js docs for the meaning of these formats:
export const MY_FORMATS = {
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-transactions-payment',
  templateUrl: './transactions-payment.component.html',
  styleUrls: ['./transactions-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class TransactionsPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentModal', { static: false }) paymentContent: ElementRef;
  @ViewChild('bills', { static: false }) billsContent: ElementRef;
  @ViewChild('transactions', { static: false }) transactionsComponent: TransactionsComponent;
  @ViewChild('payments', { static: false }) paymentsComponent: PaymentManagmentComponent;
  buildingId: string = '';
  statusConfig: any;
  transactions: boolean = false;
  payment: boolean = false;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  units: any[] = [];
  unitSelected: any;
  startDateHolder: string = '';
  endDateHolder: string = '';

  modalRef: any;
  modal: string = 'none';

  constructor(private modalService: NgbModal,
    private translateService: TranslateService,
    private onboardingService: OnboardingService,
    private userService: UserService,
    private buildingService: BuildingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildingId = this.userService.getBuilding().id;
    this.activatedRoute.params.subscribe(res => {
      if (res.view == AccountingButtons.transactions) return this.toggleView(1)
      if (res.view == AccountingButtons.paymentManagment) return this.toggleView(2)
      if (res.view == AccountingButtons.updateAccounting) return this.redirecTransactions()
      if (res.view == AccountingButtons.importedFiles) return this.exportToPDF()

      if (res.view == AccountingButtons.editDueDate) return this.openBillsModal()
      if (res.view == AccountingButtons.editUnit) return this.redirectUnits()
      if (res.view == AccountingButtons.editPaymentMethod) return this.openPaymentsModal()

      return this.goToInitialList()
    })
  }

  ngAfterViewInit(): void {
    if (this.modal.length > 0 && this.modalRef) this.modalRef.close();
    if (!this.statusConfig) this.statusConfig = this.onboardingService.getStatusConfigAccounting()
    if (this.modal == 'payment') this.modalRef = this.modalService.open(this.paymentContent, { windowClass: "payments", backdropClass: "blur-backdrop", backdrop: true, keyboard: false, centered: true, size: 'xl' });
    if (this.modal == 'bills') this.modalRef = this.modalService.open(this.billsContent, { windowClass: "bills", backdropClass: "blur-backdrop", backdrop: true, keyboard: false, centered: true, size: 'xl' });
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
  }

  goToInitialList() {
    this.router.navigate([`app/onboarding/transactions/list`])
  }

  ngOnInit(): void {
    this.getStatusConfig(this.buildingId);
    this.getUnitsByBuilding(this.buildingId);
    let language = localStorage.getItem('munily-language');
    language == 'en' ? (this.startDateHolder = 'From', this.endDateHolder = 'To') : (this.startDateHolder = 'Desde', this.endDateHolder = 'Hasta')
  }

  getStatusConfig(buildingId: string) {
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.getStatusConfig(buildingId).subscribe((resp: any) => {
      this.statusConfig = resp.data;
      this.onboardingService.setLastTransactionUpdate(this.statusConfig.lastTransactionUpdate)
    }, (error) => {
      console.log(error);
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });
  }

  getReadyModals() {
    this.statusConfig = this.onboardingService.getStatusConfigAccounting()
  }

  openPaymentsModal() {
    this.modal = 'payment';
    this.ngAfterViewInit()
  }

  openBillsModal() {
    this.modal = 'bills';
    this.ngAfterViewInit()
  }

  redirectUnits() {
    this.router.navigate(['/app/onboarding/accounts-status'], { state: { step: Steps.fromTransactionsToUnits } });
  }

  redirecTransactions() {
    this.router.navigate(['/app/onboarding/accounts-status'], { state: { step: Steps.fromTransactions } });
  }

  toggleView(number: number) {
    if (number == 1) {
      this.transactions = true;
      this.payment = false;
    } else {
      this.transactions = false;
      this.payment = true;
    }
    this.unitSelected = null;
  }

  clearFilters() {
    this.range.reset();
    this.range.markAsPristine();
    this.unitSelected = null;
    if (this.transactions) {
      this.transactionsComponent.dateFilter = null;
      this.transactionsComponent.unitId = this.unitSelected;
      this.transactionsComponent.getTransactions();
    }
    if (this.payment) {
      this.paymentsComponent.dateFilter = null;
      this.paymentsComponent.unitId = this.unitSelected;
      this.paymentsComponent.getDebts();
    }
  }

  closeModal(e?: any) {
    this.goToInitialList()
    this.modal = 'none'
    this.modalRef.close();
  }

  updateStatusFromModals(e: any) {
    this.modalRef.close();
    this.statusConfig = e.data;
    this.statusConfig.step = Steps.updateMethodOrDate;
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.updateStatusConfig(this.buildingId, this.statusConfig).subscribe((resp: any) => {
      this.statusConfig = resp.data.tmpStatusConfig;
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    }, (err: any) => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });
    this.goToInitialList()
  }

  getUnitsByBuilding(buildingId: string) {
    this.onboardingService.getUnitsByBuilding(buildingId).subscribe((resp: any) => {
      let unitsBuilding = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i].accountancy) {
          unitsBuilding.push(resp.data[i]);
        }
      }
      this.units = [...unitsBuilding];
    });
  }

  sendFilter(e?: any) {
    if (e == 'dates') {
      if (this.range.value.start && this.range.value.end) {
        this.transactionsComponent.dateFilter = this.range.value;
      } else {
        return;
      }
    }
    if (this.transactions) {
      this.transactionsComponent.unitId = this.unitSelected;
      this.transactionsComponent.getTransactions(true);
    }

    if (this.payment) {
      this.paymentsComponent.unitId = this.unitSelected;
      this.paymentsComponent.getDebts();
      if (this.paymentsComponent.dataSource.data.length != 0) return this.paymentsComponent.paginator.firstPage();
    }
  }

  exportToPDF() {
    if (this.transactions) {
      if (this.transactionsComponent.countsSelected == 0) {
        this.transactionsComponent.exportTransactions('options');
      } else {
        return;
      }
    } else if (this.payment) {
      this.paymentsComponent.exportCollectionsManagement();
    }
  }

  getEmitPaymentSelectedUnit(unitId: string | null) {
    this.unitSelected = unitId;
    this.sendFilter()
  }

}
