import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { SET_LANGUAGE } from '../component/store/actions/actions';
import { mainFeatures } from '../shared/sidebar/menu-items';
import moment from 'moment';
import { TimeLapses } from '../interface/data.interface';

@Injectable()
export class DataService {
  constructor(protected injector: Injector, protected store: Store<any>) {}

  getUser(): any {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  setUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUserInMultisession(){
    return JSON.parse(localStorage.getItem('usersSessions')) ?? [];
  }

  setUserInMultisesion(user):void{
    let sessions:any[] = JSON.parse(localStorage.getItem('usersSessions'));
    if(sessions && sessions.length > 0){
      let foundIn = sessions.findIndex((s)=> s.email == user.email);
      if(foundIn != -1){
        sessions[foundIn] = user;
      }else{
        sessions.push(user);
      }
    }else{
      sessions = [user];
    }
    localStorage.setItem('usersSessions', JSON.stringify(sessions));
  }

  getBuilding() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      return user.buildings[0];
    }
    return null;
  }

  getPlan() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      if (user.buildings[0]) return user.buildings[0].plan;
      return null;
    }
    return null;
  }

  getUid() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) return user.uid;
    return null;
  }

  getLoading() {
    return this.store.select('appReducer');
  }

  getshowModal() {
    return this.store.select('appReducer');
  }

  isLoading(obj) {
    this.store.dispatch({
      type: obj.action,
      payload: obj.payload,
    });
  }

  showModal(obj) {
    this.store.dispatch({
      type: obj.action,
      payload: obj.payload,
    });
  }

  getAppLanguage() {
    return localStorage.getItem('munily-language');
  }

  setAppLanguage(lang) {
    localStorage.setItem('munily-language', lang);
  }

  getLanguage() {
    let lang = 'es';
    this.store.select('appReducer').subscribe((items) => {
      lang = items.curLanguage;
    });
    return lang;
  }

  setLanguage(lang?) {
    const appLang = this.getAppLanguage();
    let curLang = '';

    if (!appLang) {
      this.setAppLanguage(lang);
      curLang = lang;
    } else if (appLang && appLang === lang) {
      curLang = appLang;
    } else if (appLang && appLang !== lang) {
      this.setAppLanguage(lang);
      curLang = lang;
    }
    this.store.dispatch({
      type: SET_LANGUAGE,
      payload: curLang,
    });
    
    return curLang;
  }

  getUserIsMacroCommunity(): boolean {
    const user = this.getUser();
    return user.buildings.every((building) => {
      if(building.config && building.config.macrocommunity) return building.config.macrocommunity.enable ?? false
      else false;
    });
  }

  getBuildingIsMacroFather(): boolean{
    const user = this.getUser();
    return user.buildings.every((building) => {
      if(building.config && building.config.macrocommunity){
        if(building.config.macrocommunity.enable && (building.config.macrocommunity.communities[0].type === 'macrocommunity'))
        return true;
      }
      else false;
    });
  }

  getFeatures() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user.features) {
      return user.features;
    }
    return null;
  }

  isFeatureActive(feature:mainFeatures){
    const features = this.getFeatures();
    if (features && features.length < 0) return false;
    const isActive = features.some(element => element.feature.indexOf(feature) > -1 && element.enable === true);
    return isActive;
  }

  getIntervalDates(time:TimeLapses, format:string):{ startDate: string, endDate: string }{
    let dates: any = { startDate: null, endDate: null };
    switch (time) {
      case TimeLapses.day:
      case TimeLapses.today:
        dates.startDate = moment.tz().startOf(TimeLapses.day).format(format);
        dates.endDate = moment.tz().endOf(TimeLapses.day).format(format);
      break;

      case TimeLapses.week:
        let today = moment.tz().clone().endOf(TimeLapses.week).toDate();
        dates.startDate = moment.tz().subtract(today.getDay() - 1, 'days').startOf(TimeLapses.week).format(format);
        dates.endDate = moment(today).format(format);
      break;

      case TimeLapses.month:
        let day = new Date().getDate();
        dates.startDate =
          day == 1
            ? moment.tz().startOf(TimeLapses.month).format(format)
            : moment.tz().subtract(day - 1, 'days').startOf(TimeLapses.month).format(format);
        dates.endDate = moment.tz().endOf(TimeLapses.month).format(format);
      break;

      case TimeLapses.year:
        dates.startDate = moment.tz().startOf(TimeLapses.year).format(format);
        dates.endDate = moment.tz().endOf(TimeLapses.day).format(format);
      break;

      default:
        dates.startDate = moment.tz().startOf(TimeLapses.day).format(format);
        dates.endDate = moment.tz().endOf(TimeLapses.day).format(format);
      break;
    }
    return dates;
  }

  getIntervalDays(type: string, format:string):{ startDate: string, endDate: string } {
    let today = moment.tz().endOf(TimeLapses.day).toDate(); 
    let dates: any = { 
      startDate: null, 
      endDate: today
    };

    switch (type) {
      case TimeLapses.day: //Last 24hours days
      case TimeLapses.today: //Last 24hours days
        dates.startDate = moment.tz().startOf(TimeLapses.day).format(format);
      break;

      case TimeLapses.week: //Last 7 days
        dates.startDate = moment.tz().subtract(1, TimeLapses.week).startOf(TimeLapses.day).format(format);
      break;

      case TimeLapses.month:  //Last 30 days
        dates.startDate = moment.tz().subtract(1, TimeLapses.month).startOf(TimeLapses.day).format(format);
      break;

      case TimeLapses.year:
        dates.startDate = moment.tz().subtract(1, TimeLapses.year).startOf(TimeLapses.day).format(format);
      break;
    }
    return dates;
  }

  getDatesWithinRange(startDate:string, endDate:string, dateFormat:string):string[]{
    const dates:string[] = [];
    let currentDate = moment(startDate, dateFormat);
    const finalDate = moment();
    
    while (currentDate.isSameOrBefore(finalDate)) {
      dates.push(currentDate.format(dateFormat));
      currentDate.add(1, 'day');
    }

    return dates;
  }
}
