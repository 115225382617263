import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ACTION_LOADING } from '../../component/store/actions/actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserRoles } from '../../interface/users.interface';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AmplitudeEvent } from 'src/app/interface/amplitude.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
@Output() close:EventEmitter<any> = new EventEmitter();

form:FormGroup;
mailError:string;
summited:boolean = false;

constructor(
  private formBuilder: FormBuilder,
  private authService: AuthService,
  private translateService: TranslateService,
  private toastr: ToastrService,
  private router: Router,
  private amplitudeService: AmplitudeService,
  private commonService: CommonService,
  private dataService: DataService) { 
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.form = this.formBuilder.group({
      email: [ null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  login() {
    this.summited = true;
    if (this.form.valid) {
      this.authService.isLoading({ action: ACTION_LOADING, payload: true });
      const email = this.form.get('email').value.replace(/\s/g, '');
      const password = this.form.get('password').value;
      
      let sessions = this.dataService.getUserInMultisession();
      if(sessions.length == 0) this.dataService.setUserInMultisesion(this.dataService.getUser());
      if(sessions.length == 50) return this.toastr.error(this.translateService.instant('authentication.error.cannotAssociate'));
      if(sessions.findIndex((s)=> s.email == email) != -1){
        this.authService.isLoading({ action: ACTION_LOADING, payload: false });
        return this.toastr.error(this.translateService.instant('toastrMessage.error.alreadyLogged'));
      }

      this.authService.doLogin({ email, password }).then((res) => {
        const user = this.commonService.getUser();
        if (user.superUser) this.router.navigate(['/app/dashboard-admin']);
        else {
          if (user.roles.every((rol: string) => rol != UserRoles.admin)) {
            this.authService.isLoading({ action: ACTION_LOADING, payload: false });
            return this.toastr.error(this.translateService.instant('authentication.error.rolesRequired'));
          }
          this.amplitudeService.sendToAmplitude({
            event_type: AmplitudeEvent.homeViewed,
            user_id: this.commonService.getUser().id,
          });
        }
        this.dataService.setUserInMultisesion(user);
        this.toastr.success(this.translateService.instant('toastrMessage.success.accountAdded'));
        this.closeModal({logged:true});
        const macroCommunity = user.buildings && user.buildings[0].config ? user.buildings[0].config.macrocommunity : null;
        if (macroCommunity && macroCommunity.enable && macroCommunity.communities[0] && macroCommunity.communities[0].type === 'macrocommunity') {
          this.router.navigate(['/app-community']);
        } else {
          if(user.roles.includes('admin')){
            this.router.navigate(['/app/dashboard']);
          }else if(user.roles.includes('superAdmin')){
            this.router.navigate(['/app/dashboard-admin']);
          }
        }
        window.location.reload();
        this.authService.isLoading({ action: ACTION_LOADING, payload: false });
      })
      .catch((err) => {
        if(err.code === "auth/user-not-found"){
          this.mailError = 'authentication.error.unregisteredMail';
          this.form.controls.email.setErrors({notFound:true});
        }
        if(err.code === "auth/wrong-password") this.form.controls.password.setErrors({invalidPassword:true});
        if(err.inactive) this.toastr.error(this.translateService.instant(err.message));
        this.authService.isLoading({ action: ACTION_LOADING, payload: false });
      });
    }else{
      this.mailError = 'authentication.error.invalidMail';
    }
  }

  closeModal(e:any) : void{
    this.close.emit(e);
  }

}
