import { Injectable, Injector } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import * as moment from 'moment';
import { PushNotificationsService } from 'ng-push-ivy';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  userService = this.injector.get(UserService);
  lastMessage = null;

  notifications: any = [];
  notificationsMessage: any = [];
  notificationSelected: any;

  constructor(private injector: Injector, private afMessaging: AngularFireMessaging, private _pushNotifications: PushNotificationsService) {}

  requestPermission(): Promise<string | void> {
    return new Promise((resolve, reject) =>
      this.afMessaging.requestToken.subscribe(
        (token) => {
          resolve(token);
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
          resolve();
        },
      ),
    );
  }

  receiveMessage() {
    this.afMessaging.messages.subscribe((payload: any) => {
      const last = this.currentMessage.getValue();
      if (last !== JSON.stringify(payload)) {
        this._pushNotifications.create(payload.notification.title, { body: payload.notification.body }).subscribe(
          (res:any) => {
            let noti = {
              title: res.notification.title,
              activity: 'conversation',
              body: res.notification.body,
              createdAt: moment(res.notification.createdAt).format('hh:mm a'),
            };

            if(this.notificationsMessage == null) this.notificationsMessage = [];
            this.notificationsMessage.push(noti);
            localStorage.setItem('messages_notifications', JSON.stringify(this.notificationsMessage));
          },(err) => {
            console.log(err);
          }
        );
        this.currentMessage.next(JSON.stringify(payload));
      }
    });
  }

  getNotificationMessages() {
    if (JSON.parse(localStorage.getItem('messages_notifications')) != '"null"') {
      this.notificationsMessage = JSON.parse(localStorage.getItem('messages_notifications'));
    }
  }

  deleteNotificationMessages() {
    localStorage.removeItem('messages_notifications');
    this.notificationsMessage = [];
    this.notificationSelected = null;
  }
}
