import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  newBuildingSid : string = '';
  buildingName : string = '';

  constructor( 
    private route:ActivatedRoute, 
    private publicService: PublicService, 
    private router: Router,
    private translate: TranslateService,
    private dataService: DataService) {
    const params = this.route.snapshot.queryParams;
    this.newBuildingSid = params.newBuildingSid;
  }

  ngOnInit(): void {
    this.dataService.getLoading().subscribe((state) => {
      this.translate.use(state.curLanguage);
    });
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {},replaceUrl: true });
    this.getSidName();
  }
    
  getSidName() {
    if ( !this.newBuildingSid || this.newBuildingSid ==='' ) {
      return;
    }
    this.publicService.getNameSid( this.newBuildingSid )
    .subscribe(
      (res: any) => {
        this.buildingName = res.data.building.name;
      },
      err => {
        console.error(err)
      }
    )
  }
}
