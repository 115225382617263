import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpUrlInterceptor implements HttpInterceptor {
  validatePath(path?: string): string {
    path = path ? (path.endsWith('/') ? path.substring(0, path.length - 1) : path) : '/';
    path = !path.startsWith('/') ? '/' + path : path;
    path = path.replace(/(\/)+/g, '/');
    return path.toLowerCase();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.startsWith('http') || !req.url.startsWith('/api')) return next.handle(req);
    req = req.clone({ url: `${environment.newApiUrl}${this.validatePath(req.url)}` });

    return next
      .handle(req)
      .pipe(
        map((response) => {
          if (response instanceof HttpResponse) return response.clone<any>({ body: response.body.data });
          return response;
        }),
      )
      .pipe(
        catchError((response) => {
          return throwError(response.error);
        }),
      );
  }
}
