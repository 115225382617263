import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ACTION_LOADING } from '../../../component/store/actions/actions';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
//Models
import { TransactionsFilters, ColumnsFortransactionsTable, Pagination, exportTransactionsRoute, TmpAccotingConfig } from '../../../interface/accountancy.interface';
//Service
import { OnboardingService } from 'src/app/services/onboarding.service';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
//Others
import * as moment from 'moment-timezone';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
@Input() statusConfig:TmpAccotingConfig;
accoutingSystem:string = null;
dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
displayedColumns: ColumnsFortransactionsTable[] = [ColumnsFortransactionsTable.unit, ColumnsFortransactionsTable.paidDay, ColumnsFortransactionsTable.transNo, ColumnsFortransactionsTable.type, ColumnsFortransactionsTable.amount, ColumnsFortransactionsTable.balance, ColumnsFortransactionsTable.empty];
pageEvent: PageEvent;
isVisibleDataTable: boolean = true;

@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
buildingId: string = '';
unitId:string;
dateFilter:any;
//Main Data
transactions:any[] = [];
selected: any[] = [];
countsSelected: number = 0;
//State management
checkGray: string = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-gray.png';
checkYellow: string = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-yellow.png';
buttonSelect: string = '';
selectState: boolean = false;
imageGeneral: string = this.checkGray;
pagination:Pagination = {
  page:1,
  size:10,
  sortField: 'unit',
  sortOrder: 1
};

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  unitSelected: any;
  startDateHolder: string = '';
  endDateHolder: string = '';
  @Input() units: any[] = [];
  @Output() emitSelectedUnit = new EventEmitter<any>();
  isApplyFilter: boolean = false;
  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private dataService: DataService,
    private userService: UserService,
    private onboardingService : OnboardingService) {
      this.buildingId = this.userService.getBuilding().id;
  }

  ngOnInit(): void {
    this.getTransactions();
    setTimeout(()=>{
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
      this.accoutingSystem = this.statusConfig?.accoutingSystem;
      if(this.accoutingSystem == 'other'){
        this.displayedColumns[3] = ColumnsFortransactionsTable.debit;
        this.displayedColumns[4] = ColumnsFortransactionsTable.credit;
      }
    },1500);
  }

  getTransactions(fromOutside?:boolean) {
    if(fromOutside) this.resetControlData(); //if a new query is made, the previous data that maintains control of the selected transactions is eliminated}

    let {...query} = this.pagination;
    if(this.unitId) query['unitId'] = this.unitId;
    if(this.dateFilter){
      query['dateStart'] = this.dateFilter.start.format('DD/MM/YYYY');
      query['dateEnd'] = this.dateFilter.end.format('DD/MM/YYYY');
    }

    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });

    this.onboardingService.getTransactionsByBuilding(this.buildingId, query).subscribe((resp:any)=>{
      this.transactions = resp.data.transactions;
      // To maintain control of which transactions have been selected, the src property is implemented, which saves the check that should be shown in the view, indicating the aforementioned.
      for(let t of this.transactions){
        t.paidDay = moment(t.paidDay.slice(0,10)).format('DD/MM/YYYY');
        t.debitAmount = t.debitAmount ? t.debitAmount : 0;
        t.creditAmount = t.creditAmount ? t.creditAmount : 0;
        this.selected.findIndex((trans) => trans.id == t.id ) == -1 ? t.src = this.checkGray : t.src = this.checkYellow;
      }

      this.pagination.totalDocs = resp.data.totalDocs;
      this.pagination.totalPages = Math.round(this.pagination.totalDocs / this.pagination.size);
      this.dataSource = new MatTableDataSource(this.transactions);
      setTimeout(()=> this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false }), 1000);
    },(error:any) =>{
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });
  }

  selectAllItems() {
    this.selectState = !this.selectState;
    if(this.selectState){
      this.getAllTransactionsAccordingFilters();
      this.buttonSelect = this.translate.instant('onboarding.transactions.deselectAll');
      this.transactions.map((transaction:any) => transaction.src = this.checkYellow);
      this.imageGeneral = this.checkYellow;
    }else{
      this.countsSelected = 0;
      this.selected = [];
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
      this.transactions.map((transaction:any) => transaction.src = this.checkGray);
    }
    this.imageGeneral = !this.selectState ? this.checkGray :this.checkYellow;
  }

  getAllTransactionsAccordingFilters() {
    let query:any = {};
    if(this.unitId) query['unitId'] = this.unitId;
    if(this.dateFilter){
      query['dateStart'] = this.dateFilter.start.format('DD/MM/YYYY');
      query['dateEnd'] = this.dateFilter.end.format('DD/MM/YYYY');
    }

    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.getTransactionsIdByBuilding(this.buildingId, query).subscribe((resp: any) => {
      for(let i = 0; i < resp.data.length; i++){
        this.selected.findIndex(trans => trans.id == resp.data[i].id) == -1 ? this.selected.push(resp.data[i]) : null;
      }
      this.countsSelected = this.selected.length;
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    }, error => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });
  }

  changeSelected(transaction:any) {
    let isSelected = false;
    let indexFoundIt = this.selected.findIndex((trans) => trans.id == transaction.id );
    if(indexFoundIt != -1) isSelected = true;

    if(isSelected){
      this.selected.splice(indexFoundIt, 1);
      this.countsSelected = this.countsSelected - 1;
    } else {
      this.selected.push({id: transaction.id});
      this.countsSelected = this.countsSelected + 1;
    }

    // We change the functionality of the button according to the selected elements
    if(this.selected.length >= 1){
      if(this.selectState) this.buttonSelect = this.translate.instant('onboarding.transactions.deselectAll');
    }else{
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
    }
  }

  selectTransaction(transaction:any, id:string) {
    transaction.src = transaction.src == this.checkGray ? this.checkYellow : this.checkGray;
    this.changeSelected(transaction);
    let selectAll = document.getElementById('selectAll') as HTMLImageElement;
    selectAll.src = this.checkGray;
  }

  onPageEvent(event) {
    this.pagination.page = event.pageIndex + 1;
    this.pagination.size = event.pageSize;
    this.getTransactions();
  }

  sortTransactions(e:any){
    if(e.active == ColumnsFortransactionsTable.empty) return;
    this.paginator.firstPage();
    if(e.direction !== ''){
      this.pagination.sortField = e.active;
      this.pagination.sortOrder = e.direction == 'asc' ? 1 : -1;
    }else{
      this.pagination.sortField = 'unit';
      this.pagination.sortOrder = 1;
    }
    this.getTransactions();
  }

  exportTransactions(from?:string){
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    let {...query}:TransactionsFilters = this.pagination;

    if(from && from == exportTransactionsRoute.options){
      if(this.unitId) query['unitId'] = this.unitId;
      if(this.dateFilter){
        query['dateStart'] = this.dateFilter.start.format('DD/MM/YYYY');
        query['dateEnd'] = this.dateFilter.end.format('DD/MM/YYYY');
      }
    }

    if(this.selected.length > 0){
      query.transactionsSelect = [];
      for(let sel of this.selected){
        query.transactionsSelect.push(sel.id);
      }
    }
    query.i18n = this.dataService.getLanguage();

    if(from == exportTransactionsRoute.options) query.fromOptions = true;

    let building = this.dataService.getBuilding();
    this.onboardingService.exportTransactions(building.id, query).subscribe((resp:any)=>{
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
      saveAs(resp.data.url, "Transactions.pdf");
    },( error:any ) =>{
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
      this.toastr.error(error.error.message);
    });
  }

  resetControlData(){
    this.imageGeneral = this.checkGray;
    // this.selected = [];
    this.selectState = false;
    // this.countsSelected = 0;
    this.paginator.firstPage();
    this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
  }

  onEventFilterDate(e: string) {
    if (this.unitSelected === null && !this.range.value.start && !this.range.value.end) {
      this.isApplyFilter = false;
    }

    if (e.includes("dates") && this.range.value.start && !this.range.value.end) {
      return;
    }

    if (e.includes("dates") && this.range.value.start && this.range.value.end) {
      this.dateFilter = this.range.value;
      this.isApplyFilter = true;
    }

    if (this.unitSelected !== null) this.isApplyFilter = true;
    this.unitId = this.unitSelected;

    this.getTransactions(true);
  }

  onEventClearFilter() {
    this.isApplyFilter = false;
    this.dateFilter = null;
    this.unitId = null;
    this.unitSelected = null;
    this.range.reset();
    this.getTransactions(true);
  }
}
