
import {from as observableFrom,  Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { resolve } from 'path';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  dataService: any;
  authService: any;
  fbToken:string;

  constructor(
    public afAuth: AngularFireAuth,
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if(user && user.fbToken) this.fbToken = user.fbToken;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.dataService = this.injector.get(DataService);
    this.authService = this.injector.get(AuthService);

    return observableFrom(this.handleAccess(req, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    await this.afAuth.authState.subscribe((resp:firebase.User)=>{
      if(resp) this.fbToken = resp['_lat'];
    })

    const lang = this.dataService.getAppLanguage();
    const user = this.dataService.getUser();
    if (user) {
      user.fbToken = this.fbToken;
      this.dataService.setUser(user);
      const token = user.token;
      let building = null;
      let language = null;
      if (!user.superUser && user.buildings) building = user.buildings[0]._id;
      if (lang) language = lang;

      request = this.applyCredentials(request, this.fbToken, token, building, language);
    }

    return next.handle(request).toPromise();
  }

  applyCredentials(req: HttpRequest<any>, fbToken, token, buildingId, language) {
    const items: any = {};
    // if (token) items['Content-Type'] = 'application/json';
    if (token) items['Authorization'] = `Bearer ${token}`;
    if (fbToken) items['Fb-Token'] = fbToken;
    if (buildingId) items['buildingId'] = buildingId;
    if (language) items['munilyLanguage'] = language;
    items['platform'] = 'webpage';
    
    return req.clone({ setHeaders: items });
  }
}
