import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DinamycLinkComponent } from './dinamyc-link/dinamyc-link.component';
import { ModalDownloadAppComponent } from "./modal-download-app/modal-download-app.component";
import { ModalNotificationComponent } from './modal-notification/modal-notification.component';
import { BuildingSidComponent } from './building-sid/building-sid.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { CancelChangeComponent } from './cancel-change/cancel-change.component';

const routes: Routes = [
  { path: 'dynamic-link', component: DinamycLinkComponent },
  { path: 'invitation_response', component: ModalDownloadAppComponent},
  { path: 'notifications', component: ModalNotificationComponent},
  { path: 'buildings', component: BuildingSidComponent },
  { path: 'create-user', component: CreateUserComponent },
  { path: 'cancel-change', component: CancelChangeComponent },
  { path:"**", redirectTo: '/authentication/404', pathMatch: 'full'}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
