<div class="container-fluid row no-gutters">
	<div class="col-md-1 section-images">
		<img class="m-4" src="https://munily-public-cdn.s3.amazonaws.com/public/dashboard/munily.png" height="36px">
	</div>
	<div class="section-1 col-12 col-md-5 px-0">
		<img class="img-fluid" src="https://munily-public-cdn.s3.amazonaws.com/donwload-app/devices_donwload_app.png">
	</div>
	<div class="section-2 col-12 col-md-6 px-0">
		<div class="row mt-md-5 ml-5">
			<div class="col-8 col-xs-12">
                <img class="img-fluid" src="https://munily-public-cdn.s3.amazonaws.com/donwload-app/icon_enter_from_app.png">
			</div>
            <div class="col-8 col-xs-12 mt-2">
				<h1 class="m-0 mr-2">{{ 'downloadApp.title' | translate }}</h1>
			</div>
            <div class="col-8 col-xs-12 mt-2">
                <p>
                	{{ 'downloadApp.description' | translate }}
				</p>
			</div>
		</div>
	</div>
</div>
