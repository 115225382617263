export const environment = {
  production: true,
  apiUrl: 'https://api.munily.com/api',
  newApiUrl: 'https://api.munily.com',
  baseUrl: 'https://dashboard.munily.com',
  socketUrl: 'https://socket.munily.com',
  firebase: {
    apiKey: 'AIzaSyDvbymJjE3jW7HC4ZhAbJFiDlwRgTGCH7U',
    authDomain: 'munily-test.firebaseapp.com',
    databaseURL: 'https://munily-test.firebaseio.com',
    projectId: 'munily-test',
    storageBucket: 'munily-test.appspot.com',
    messagingSenderId: '728423469602',
    appId: '1:728423469602:android:ea2f0839eb20e7e4'
  },
  geoapifyUrl: 'https://api.geoapify.com/v1/geocode/search?text',
  geoapifyApiKey: 'db85710bfb124a93a7d465a5b9355bb8',
  amplitudeApiKey: 'a5b7f6b91a5fb90dd5878d21f13bf781',
  amplitudeEnabled: true,
  envDeployed: 'prod'
};
