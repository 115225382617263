
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class ChatService {
  currConversation: any = null;
  openConversations = [];

  conversations: any[] = [];
  messages: any[] = [];

  constructor(private http: HttpClient) {
  }

  getUnreadMessages(conversation, userId) {
    const unread = parseInt(conversation.participants[userId].unRead);
    if (conversation.participants[userId].msgPending || (unread && unread > 0)) {
      return true;
    }
    return false;
  }

}
