<ng-template #content let-c="close" let-d="dismiss">
  <form (ngSubmit)="onSubmit()">
    <div class="custom--modal-form">
      <div class="modal-header d-flex align-items-center pb-2">
        <h4 class="modal-title">{{ 'header.password.title' | translate }}</h4>
        <button type="button" class="close px-0" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold">{{ 'modal.currentPassword' | translate }} *</label>
              <input
                type="password"
                class="form-control form-control-lg rounded"
                placeholder="{{ 'modal.placeholder.password' | translate }}"
                [formControl]="form.controls['currentPassword']"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold">{{ 'modal.newPassword' | translate }} *</label>
              <input
                type="password"
                class="form-control form-control-lg rounded"
                placeholder="{{ 'modal.placeholder.password' | translate }}"
                [formControl]="form.controls['password']"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold">{{ 'modal.confirmPassword' | translate }} *</label>
              <input
                type="password"
                class="form-control form-control-lg rounded"
                placeholder="{{ 'modal.placeholder.password' | translate }}"
                [formControl]="form.controls['confirmPassword']"
                required
              />
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="font-weight-bold">{{'modal.currentPassword' | translate}} *</label>
              <input type="password" class="form-control"
                     placeholder="{{'modal.placeholder.password' | translate}}"
                     [formControl]="form.controls['currentPassword']" required/>
            </div>
          </div>
        </div> -->
      </div>
      <div class="modal-footer d-flex align-items-center justify-content-center">
        <button type="submit" class="btn btn-warning w-25 rounded" [disabled]="loading">{{ 'modal.button.send' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>
