import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AmplitudeEvent } from 'src/app/interface/amplitude.interface';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-bills-modal',
  templateUrl: './bills-modal.component.html',
  styleUrls: ['./bills-modal.component.scss']
})
export class BillsModalComponent implements OnInit {
  @Input() statusConfig:any;
  //Events
  @Output() send = new EventEmitter();
  @Output() cancel = new EventEmitter();
  //Form
  form:FormGroup;
  regExpNumbers:any = new RegExp('\d*');
  sentToAmplitude: boolean = false;

  constructor(private formBuilder: FormBuilder, private amplitudeService: AmplitudeService, private commonService: CommonService,) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({'days':[ this.statusConfig.daysOverdue ? this.statusConfig.daysOverdue : '', [Validators.required, Validators.pattern(/^\d+$/)]]});
  }

  sendDays(){
    this.statusConfig['daysOverdue'] = this.form.value.days;
    this.send.emit({ openModal:'stepper', continue:true, from: 'bills', data: this.statusConfig });
    if (!this.sentToAmplitude) this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.expirationDateAdded,
      user_id: this.commonService.getUser().id,
    });
    this.sentToAmplitude = true;
  }

  cancelModal(){
    this.cancel.emit({ openModal:'stepper', continue:false, from: 'bills' });
  }

  checkNumbers(){
    let valueN = this.form.controls.days.value;
    //First Validation: Be a number
    if(!(/^\d+$/.test(valueN))){
      this.form.controls.days.setValue(valueN.slice(0,-1));
    }
    //Second Validation: Be in a range of 1 to 31
    if(valueN && valueN > 31) this.form.controls.days.setValue(31);
    if(valueN && valueN < 0) this.form.controls.days.setValue(1);
  }

}
