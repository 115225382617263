import { ACTION_LOADING, GET_LANGUAGE, SET_LANGUAGE, SHOW_MODAL } from '../actions/actions';

export interface appReducerState {
  isLoading: boolean;
  curLanguage: string;
  show: boolean;
  noticeId: string;
}

const initialState: appReducerState = {
  isLoading: false,
  curLanguage: 'es',
  show: false,
  noticeId: '0',
};

export function reducer(state = initialState, action): appReducerState {
  switch (action.type) {
    case ACTION_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_LANGUAGE:
      return state;
    case SET_LANGUAGE:
      return {
        ...state,
        curLanguage: action.payload,
      };
    case SHOW_MODAL:
      return {
        ...state,
        show: action.payload.show,
        noticeId: action.payload.noticeId,
      };
  }
  return state;
}
