import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-datatable',
  templateUrl: './empty-datatable.component.html',
  styleUrls: ['./empty-datatable.component.css'],
})
export class EmptyDatatableComponent implements OnInit {
  @Input('empty') emptyIndex;
  empty;
  emptys = [
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/houston.png',
      title: 'building.tab.emptyState.houstonReq',
      subtitle: 'building.tab.emptyState.houstonRes',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/ups.png',
      title: 'building.tab.emptyState.upsTitle',
      subtitle: 'building.tab.emptyState.upsSubtitle',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/gifbox.png',
      title: 'building.tab.emptyState.giftboxTitle',
      subtitle: 'building.tab.emptyState.giftboxSubtitle',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/houston.png',
      title: 'building.tab.emptyState.houstonReq',
      subtitle: 'Positivo, no hay un comunicado seleccionado !Rayos¡',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/houston.png',
      title: 'building.tab.emptyState.houstonReq',
      subtitle: 'onboarding.transactions.emptyStateAccountState',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/ups.png',
      title: 'notice.emptyState.title',
      subtitle: 'notice.emptyState.subtitle',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/ups-magnifier.png',
      title: 'notice.emptyState.upsTitle',
      subtitle: 'notice.emptyState.upsSubtitle',
    },
    {
      img: 'https://munily-public-cdn.s3.amazonaws.com/buildings/superadmin/ups-magnifier.png',
      title: 'onboarding.empty.title4',
      subtitle: 'onboarding.empty.subtitle4',
    },
  ];
  constructor() { }

  ngOnInit(): void {
    this.empty = this.emptys[this.emptyIndex];
  }
}
