import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';
import { MessageNotificationResponse } from '../interface/chat.interface';

@Injectable()
export class ChatV2Service extends DataService {
  socket: Socket;
  headers: HttpHeaders;

  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).fbToken : null,
      buildingId: this.getBuilding()._id
    });
  }

  connect() {
    this.socket = new Socket({
      url: environment.socketUrl + '/chat',
      options: {
        query: {
          authorization: this.getUser().token,
          building: this.getBuilding()._id,
        },
        transports: ['websocket'],
      },
    });
  }

  disconnect() {
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }

  getEnableChats(data: any) {
    let params = new HttpParams().append('page', data.page).append('size', data.size);
    return this.http.get<any>('/api/admin/v1/chat/enable-chats-paginated',{ params: params });
  }

  getMessagePaginated(data: any) {
    let params = new HttpParams().append('page', data.page).append('size', data.size);

    return this.http.get<any>(`/api/admin/v1/chat/messages/${data.roomId}`, { params: params });
  }

  getAvaliableClass(search?: string) {
    let params = new HttpParams();
    if (search) params = params.append('search', '' + search);
    return this.http.get<any>('/api/admin/v1/chat/available-chats', { params: params });
  }

  uploadAttachment(file: FormData, room:string) {
    return this.http.post(`/api/admin/v1/chat/${room}/attachment`, file, { headers: this.headers });
  }

  sentMessageActionResponse(buildingId:string, payload:MessageNotificationResponse){
    return this.http.put(`/api/admin/v1/chat/action-message`, payload, { headers: this.headers });
  }
}
