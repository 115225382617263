import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuildingQueryParams, BuildingResponsePaginated, BuildingResponseRequest } from '../interfaces/building.interface';

@Injectable()
export class BuildingMacroCommunityService extends DataService {
  headers: HttpHeaders;
  constructor(private httpClientUser: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).token : null,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')).fbToken : null,
    });
  }

  getCommunitiesBuilding(queryParams: BuildingQueryParams): Observable<BuildingResponsePaginated> {
    let params = new HttpParams();
    if (queryParams.all) params = params.set('all', queryParams.all.toString());
    if (queryParams.buildingId) params = params.set('building', queryParams.buildingId);
    return this.httpClientUser
      .get<BuildingResponseRequest>(`${environment.apiUrl}/admin-macro/v1/buildings`, { headers: this.headers, params })
      .pipe(map((resp) => resp.data.buildings));
  }
}
