import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidateTransactionsReponse } from 'src/app/interface/accountancy.interface';

@Component({
  selector: 'app-compatibility-modal',
  templateUrl: './compatibility-modal.component.html',
  styleUrls: ['./compatibility-modal.component.scss']
})

export class CompatibilityModalComponent implements OnInit, OnChanges {
@Input() typeModal: string = "compability";
@Input() compatibilityModal: number = 0;
@Input() transactions: ValidateTransactionsReponse;
@Output() closeModal = new EventEmitter();


compatibilityFilesModal: any[] = [
  //File Error Reference
  {title: this.translate.instant('modal-files-errors.not-compatible.title'), subtitle: this.translate.instant('modal-files-errors.not-compatible.subtitle'), imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/error_not_xlsx.png"},
  {title: this.translate.instant('modal-files-errors.error-reading.title'), subtitle: this.translate.instant('modal-files-errors.error-reading.subtitle'), imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/file_error.png"},
  {title: this.translate.instant('readerExcel.errors.documentAvoid'), subtitle:null, imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/file_empty.png"},
  //Success notices
  {title: this.translate.instant('modal-compatibility.success-notices.changed'), subtitle:null, imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/changes_saved.png"},
  {title: this.translate.instant('modal-compatibility.success-notices.import'), subtitle:null, imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/imported.png"},
  {title: this.translate.instant('modal-compatibility.success-notices.transactions',{ transactions: 0 }), subtitle:null, imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/transactions_imported.png"},
  {title: this.translate.instant('modal-compatibility.success-notices.notifications'), subtitle:null, imgUrl:"https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/notification_sent.png"},
  //Error in Transactions import
  {title: this.translate.instant('readerExcel.errors.fileWithInvalidData'), subtitle: this.translate.instant('modal-files-errors.fileWithInvalidData.subtitle'), imgUrl:"ºhttps://munily-public-cdn.s3.amazonaws.com/shared/dashboard/error_not_xlsx.png"}
];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.compatibilityModal.currentValue == 5){
      if(changes.transactions.currentValue){
        this.compatibilityFilesModal[5].title = this.translate.instant('modal-compatibility.success-notices.transactions',{ transactions: changes.transactions.currentValue.transactions.data.length });
      }
    }
  }


  close(option:any){
    this.closeModal.emit({state:option, type: this.typeModal, modal: this.compatibilityModal});
  }

}
