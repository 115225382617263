import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { Observable } from 'rxjs';

@Injectable()
export class Init implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.authService.getUser();

    if (!user) {
      this.router.navigate(['/authentication/login']);
      return false;
    } else {
      const isMacroCommunity = this.authService.getUserIsMacroCommunity();
      let routeBasic = '/app/dashboard';
      let roleUser = user.roles.includes("customized");

      if (isMacroCommunity && !user.superUser) routeBasic = '/app-community';
      if (!isMacroCommunity && user.superUser || roleUser) routeBasic = '/app/dashboard-admin';
      this.router.navigate([routeBasic]);
      return true;
    }
  }
}
