import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent implements OnInit {
@Input('sessions') sessions:any[];
@Input('sessionSelected') session:any;
@Input('allSessions') allSessions:boolean;
@Output() close:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeSession(): void{
    if(!this.allSessions){
      let index = this.sessions.findIndex((s)=> s.id == this.session.id);
      this.sessions.splice(index,1);
    }
    this.closeModal({logout:true, sessions: this.sessions});
  }

  closeModal(e:any): void{
    this.close.emit(e);
  }

}
