import { UserRoles } from "./users.interface";
import { Unit } from "./units.interface";
import { User } from "./users.interface";
import { Building } from "./buildings.interface";

export enum AccountingSystems {
  sage50 = "sage50",
  other = "other"
}

export enum ModalsType {
  compatibility = 'compatibility',
  stepper = 'stepper',
  dontSave = 'dontSave',
  importing = 'importing',
  overwrite = 'overwrite'
}

export enum Steps {
  importUnits = 'importUnits',
  importUnitsInProgress = 'importUnitsInProgress',
  importTransactions = 'importTransactions',
  paymentManagement = 'paymentManagement',
  updateMethodOrDate = 'updateMethodOrDate',
  updateUnits = 'updateUnits',
  validation = 'validation',
  fromTransactionsToUnits = 'fromTransactionsToUnits',
  fromTransactions = 'fromTransactions'
}

export enum importStatus {
  imported = 'imported',
  pending = 'pending',
  failed = 'failed',
}

export class Pagination {
  page: number;
  size: number;
  sortField?: string;
  sortOrder?: number;
  totalDocs?: number;
  totalPages?: number;
}

export enum exportTransactionsRoute {
  options = 'options',
  modal = 'modal'
}

export enum ColumnsFortransactionsTable {
  unit = 'unit',
  paidDay = 'paidDay',
  transNo = 'numberTransaction',
  type = 'type',
  debit = 'debit',
  credit = 'credit',
  amount = 'amount',
  balance = 'balance',
  empty = "empty"
}

export enum ColumnsForCollectionsManagement {
  select = 'select',
  unit = 'unit',
  expiredDays = 'expiredDays',
  expiredDebt = 'expiredDebt',
  debtToBeDue = 'debtToBeDue',
  totalDebt = 'totalDebt',
  status = "status",
  actions = "actions"
}

export enum ColumsForTransactionsHistory {
  date = 'date',
  file = 'file'
}

//Units interfaces

export interface ICardsproperties {
  amount: number,
  percentage: number,
  units: number
}

export interface IAccountancyCards {
  data: {
    unexpired: ICardsproperties,
    expired60Days: ICardsproperties,
    expired30To60Days: ICardsproperties,
    expired1To30Days: ICardsproperties
  }
}

export interface IAccountancy {
  lastDatePaid: string;
  totalDebt: number;
  expiredDebt: number;
  debtDue: number;
  daysOwed: number;
}

export interface IUnitAccounting {
  accountancy: IAccountancy,
  number: string,
  id: string
}

export class TmpMatchedUnit {
  unitMun: Unit | string;
  unitSage: string;
  newUnit: boolean;
  proprietors: any[];
  tenants: any[];
}

export class SageTmpUnits {
  accountingName: string;
  newUnit: boolean;
  proprietorsSelected: any[];
  tenants: any[];
  unitId: string;
}

export class TmpUnits {
  allUnits: {
    accountingName: string;
    fakeId: string;
    id: string;
    line: number
    match: boolean
    newUnit: boolean
    number: string
    proprietorsSelected: any[]
    tenants: any[]
  }[];
  sagePending: SageTmpUnits[];
}

export class UsersOfUnitsMatched {
  id: string;
  name: string;
  email: string;
  roles: UserRoles[];
  selected: boolean;
}

export class UnitsMatchedResponse {
  id: string;
  number: string;
  match: boolean;
  accountingName: string;
  proprietors: UsersOfUnitsMatched[];
  tenants: UsersOfUnitsMatched[];
}

//Transactions interfaces
export interface IssuesOfTransactionsFile {
  notAssociated: string[];
  dateFormat: string[];
  wrongType: string[];
  emptyCell: string[];
  nonNumericalAmount: string[];
  nonNumericalBalance: string[];
}

export interface TransactionsFormatFromXLSX {
  balance: string;
  creditAmt: string;
  debitAmt: string;
  customer: string;
  customerId: string;
  type: string;
  transNo: number;
  date: Date | null;
}

export interface ValidateTransactionsReponse {
  transactions: {
    data: TransactionsFormatFromXLSX[];
    news?: number;
    overwrited?: number;
  };
  issues: {
    invalid: boolean;
    data: IssuesOfTransactionsFile;
  };
  importId?: string;
  intervalDates?: {
    from: Date | string,
    to: Date | string
  };
}

export class TransactionsImport {
  id: string;
  user: User | string;
  building: Building | string;
  fileName: string;
  fileUrl: string;
  status: importStatus;
  importedAt: Date;
  updatedAt?: Date;
  createdAt?: Date;
}

export class TransactionsFilters {
  page: number;
  size: number;
  transactionsSelect?: string[];
  selectedAll?: boolean;
  unitId?: string;
  sortField?: string;
  sortOrder?: number;
  startDate?: Date;
  endDate?: Date;
  fromOptions?: boolean;
  i18n?: string;
}

export class UnitsAccountingFilters {
  unitsSelect?: string[];
  selectedAll?: boolean;
  unitId?: string;
  sortField?: string;
  sortOrder?: number;
  intervalStatus?: string;
}

//CONFIGURATION
export interface TmpAccotingConfig {
  step: Steps;
  stepper: {
    step: number;
    status: string;
  };
  paymentMethods?: string;
  accoutingSystem?: string;
  systemDescription?: string;
  daysOverdue?: number;
  matchedUnits?: TmpMatchedUnit[];
  progressOfUnits?: TmpUnits;
  transactionsTmp?: {
    transactions: TransactionsFormatFromXLSX[];
    import: TransactionsImport | string;
  };
}

export const IAccountancyDays = {
  all: {
    name: 'onboarding.managment.all',
    background: '',
    filter: ''
  },
  upToDate: {
    name: 'onboarding.managment.upToDay',
    background: '#A0E7D0',
    borderColor: '#369752',
    filter: 'unexpired'
  },
  expired1To30Days: {
    name: '01 - 30',
    background: '#C3DCFA',
    borderColor: '#1E5EA4',
    filter: 'expired30'
  },
  expired31To60Days: {
    name: '31 - 60',
    background: '#FFCF74',
    borderColor: '#BD6F12',
    filter: 'expired60'
  },
  expired60Days: {
    name: '> 60',
    background: '#F9AFB3',
    borderColor: '#B3373F',
    filter: 'expired60more'
  },
} as const;