import { mainFeatures } from "../shared/sidebar/menu-items";

export class FeaturesMenuItems {
  code: FeaturesDBCode;
  feature: mainFeatures[];
}

export enum FeaturesDBCode {
  fastPass = 'F01',
  comunily = 'F02',
  reservations = 'F03',
  default = 'F04',
  delivery = 'F05',
  notices = 'F06',
  providers = 'F07',
  chats = 'F08',
  frequentAccess = 'F09',
  visits = 'F10',
  parcel = 'F11',
  accountStatements = 'F12',
  pqrs = 'F13',
  accessManagement = 'F14',
  securityCenters = 'F15'
}

export enum Features {
  fastPass = 'fastPass',
  comunily = 'comunily',
  reservations = 'reservations',
  default = 'default',
  delivery = 'delivery',
  notices = 'notices',
  providers = 'providers',
  chats = 'chats',
  frequentAccess = 'frequentAccess',
  visits = 'visits',
  parcel = 'parcel',
  accountStatements = 'accountStatements',
  pqrs = 'pqrs',
  accessManagement = 'accessManagement',
  securityCenters = 'securityCenters'
}

export const FEATURES_EXCLUDE_SIDEBAR: mainFeatures[] = []

export const FEATURES_DASHBOARD: FeaturesMenuItems[] =
[
  { code: FeaturesDBCode.reservations, feature: [mainFeatures.reservations]},
  { code: FeaturesDBCode.notices, feature: [mainFeatures.notices]},
  { code: FeaturesDBCode.chats, feature: [mainFeatures.chats]},
  { code: FeaturesDBCode.frequentAccess, feature: [mainFeatures.frequentAccess]},
  { code: FeaturesDBCode.visits, feature: [mainFeatures.visits]},
  { code: FeaturesDBCode.accountStatements, feature: [mainFeatures.reservations]},
  { code: FeaturesDBCode.accessManagement, feature: [mainFeatures.accessManagement]},
  { code: FeaturesDBCode.securityCenters, feature: [mainFeatures.securityCenters]}
];
