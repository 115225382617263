import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class NoticeService extends DataService {
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  fetchNotice(page, limit, search?) {
    const params = search ? `&${search}` : '';
    return this.http
      .get(`${environment.apiUrl}/notice?page=${page}&rowsPerPage=${limit}${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  storeNotice(notice) {
    const building = this.getUser().buildings[0];
    notice.building_id = building._id;

    return this.http
      .post(`${environment.apiUrl}/notice`, { notice })
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  updateNotice(id, status) {
    return this.http
      .put(`${environment.apiUrl}/notice/update-status/${id}`, { status })
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  readNotice(id, userId) {
    const object = {
      read: true,
      userId: userId,
    };
    return this.http
      .put(`${environment.apiUrl}/notification/${id}`, object)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  uploadFile(files: any) {
    return new Promise((resolve, reject) => {
      try {
        if (files.length > 0) {
          const formdata: FormData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formdata.append('files[]', files[i]);
          }
          this.http
            .post(`${environment.apiUrl}/notice/upload-file`, formdata)
            .toPromise()
            .then((res: any) => {
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              return reject(err);
            });
        } else {
          resolve(true);
        }
      } catch (err) {
        console.log('upload err: ', err);
        reject(err);
      }
    });
  }
}
