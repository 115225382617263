import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MacroAdminRol implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // const isMacroCommunity = this.authService.getUserIsMacroCommunity();
    const isMacroCommunityFather = this.authService.getBuildingIsMacroFather();
    if (isMacroCommunityFather) return true;
    else {
      this.router.navigate(['/app/dashboard']);
      return false;
    };
  }
}
