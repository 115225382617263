import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SuperAdminRol implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.authService.getUser();
    if (!user) {
      this.router.navigate(['/authentication/login']);
      return false;
    }
    const isSuperAdmin = user.roles.indexOf('superAdmin') > -1;
    if (isSuperAdmin) return true;
    else false;
  }
}
