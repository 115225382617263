import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class CountryService extends DataService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
  }

  getcountriesAndTimezone() {
    return this.http.get(`${environment.apiUrl}/public/v1/countries`);
  }

  getOperationZone(countryId:string) {
    return this.http.get(`${environment.apiUrl}/public/v1/operation-zone/?countryId=${countryId}&all=true`, { headers: this.headers });
  }

  getRegions() {
    return this.http.get(`${environment.apiUrl}/super/v1/regions/get-regions`, { headers: this.headers });
  }

  getSectors() {
    return this.http.get(`${environment.apiUrl}/super/v1/sectors/get-sectors`, { headers: this.headers });
  }

  fetchCountries() {
    return this.http
      .get(`${environment.apiUrl}/country`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
}
