export const datadogConfig = {
  applicationId: '77699d0c-8b29-44b2-b3e1-73dcdb3b485f',
  clientToken: 'pub1b2a6796831032cfdbc9e05e697cf216',
  site: 'datadoghq.com',
  service:'munily-dashboard',
  env:'DEV',
  sampleRate: 5,
  trackInteractions: true
};

// SourceMap key
// key ID: 6c55c2bf-62f6-417b-8964-c896ae000524
// pass: e6dc3728756b8dea5babac7fb1446c9a

