import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SuperAdminMunilyPayRol implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.authService.getUser();
    if (!user) {
      this.router.navigate(['/authentication/login']);
      return false;
    };
    //IMPORTANTE CAMBIAR EL arreglo de correos, a una propiedad en la configuracion de usuarios de superadmin
    //que se evalue y de acceso a esta funcionalidad
    return ['mitchell@munily.com', 'ycamargo@munily.com'].includes(user.email);
  }
}
