import { Injectable } from '@angular/core';

@Injectable()
export class Status {
  // constructor() {}
  INACTIVE_AREA = 'inactive';
  ACTIVE_AREA = 'active';
  MAINTANCE_AREA = 'maintance';
}

export const VisitsStatus = {
  created: 'status.invitations.created',
  checked: 'status.invitations.checked',
  approved: 'status.invitations.approved',
  created_approved: 'status.invitations.created_approved',
  authorized_by_call : 'status.invitations.authorized_by_call',
  unauthorized_by_call : 'status.invitations.unauthorized_by_call',
  without_response_by_call : 'status.invitations.without_response_by_call',
  not_answer_by_call : 'status.invitations.not_answer_by_call',
  concreted: 'status.invitations.concreted',
  canceled: 'status.invitations.canceled',
  not_completed: 'status.invitations.not_completed',
  denied: 'status.invitations.denied',
  without_tenant: 'status.invitations.without_tenant',
  without_connection: 'status.invitations.without_connection',
  left:'status.invitations.left'
};
