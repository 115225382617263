<div class="preloader modal-notice" *ngIf="showPanel">
  <div class="notices-panel">
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input
            type="text"
            placeholder="{{ 'notices.search' | translate }}"
            class="form-control input-search"
            [(ngModel)]="search"
            (keyup.enter)="searchInteraction()"
          />
          <div class="input-group-append-date2">
            <i class="mdi mdi-magnify icon-template" (click)="searchInteraction()"></i>
          </div>
          <div class="text d-flex close-button" title="{{ 'building.tab.settings.close' | translate }}" (click)="closePanel()">
            <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/crossBlue.png" height="15px" width="15px" />
          </div>
        </div>

        <div class="mt-2 heigthTable" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="3" (scrolled)="onScroll($event)" >
          <div class="border-content mt-1" *ngFor="let interaccion of interacciones; let i = index">
            <label class="text-content-two"> {{ interaccion.user.name }}</label>
            <div class="row p-2">
              <!-- MOVIL -->
              <div class="col text-left">
                <img src="https://munily-public-cdn.s3.amazonaws.com/general/icons/mobile.svg" />
                <!-- BLACKS -->
                <div class="chulos-template" *ngIf="interaccion.read && !interaccion.email.notUser">
                  <!-- <img class="ml-1" src="assets/images/munily/checkBlack.svg"> -->
                  <img class="ml-1" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/boldCheck.svg" height="15px" />
                  <img class="ml-1" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/boldCheck.svg" height="15px" />
                </div>
                <div class="chulos-template" *ngIf="!interaccion.read && interaccion.notification.sent && !interaccion.email.notUser">
                  <img class="ml-2" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/boldCheck-grey.svg" height="15px" />
                </div>
                <div class="chulos-template" *ngIf="interaccion.notification.notUser">
                  {{ 'notices.status.down' | translate }}
                </div>
              </div>
              <!-- PC -->
              <div class="col text-center" style="padding: 0">
                <img src="https://munily-public-cdn.s3.amazonaws.com/general/icons/ic_desktop_mac_48px.svg" />
                <div class="chulos-template" *ngIf="interaccion.email.sent && !interaccion.email.notUser">
                  <img class="ml-2" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/boldCheck-grey.svg" height="15px" />
                </div>
                <div class="chulos-template" *ngIf="interaccion.email.notUser">
                  {{ 'notices.status.down' | translate }}
                </div>
              </div>
              <div class="col text-center" style="font-size: 21px; padding: 0">
                {{ interaccion.emoji.ico }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
