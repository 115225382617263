import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { environment } from 'src/environments/environment';
import { AmplitudeGenericEvent } from '../interface/amplitude.interface';

@Injectable()
export class AmplitudeService {
  constructor() {
  }

  sendToAmplitude(event: AmplitudeGenericEvent) {
    if (environment.amplitudeEnabled) {
      const instance = amplitude.getInstance();
      instance.init(environment.amplitudeApiKey, event.user_id);
      instance.logEvent(event.event_type, event.event_properties);
    }
  }
}
