<div class="bill-section" >
    <div class="container-inside">
        <div class="text-content">
            <h1 class="col-12">{{ 'bill-modal.text-content.title' | translate }}</h1>
            <div class="description">
                <p>{{ 'bill-modal.text-content.desc_1' | translate }}</p>
                <p>{{ 'bill-modal.text-content.desc_2' | translate }}</p>
            </div>
            <div class="input-section" [formGroup]="form">
                <input class="form-control" type="text" min="1" max="31" formControlName="days" (keyup)="checkNumbers()">
                <p class="days">/{{ 'building.tab.areas.days' | translate }}</p>
            </div>
        </div>
    </div>
</div>
<div class="footer row">
    <div class="button-line-inside col-4">
        <button class="back" (click)="cancelModal()">
            <img class="pl-1" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_black.svg" alt="arrow"> {{ 'buttons.return' | translate }}
        </button>
    </div>
    <div class="button-line-inside col-4 offset-4">
        <button class="next active" (click)="sendDays()" [ngClass]="{
            'active': form.valid,
            'inactive': form.invalid
          }">
            {{ 'buttons.accept' | translate }}
            <img class="pl-1" *ngIf="form.valid" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_white.png" alt="arrow">
            <img class="pl-1" *ngIf="form.invalid" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/arrow_gray.png"  alt="arrow">
        </button>
    </div>
</div>
