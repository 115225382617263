<!-- Compatibility notices -->
<div class="compatibility-modal" *ngIf="typeModal == 'compatibility'" #compatibility>
  <div class="img-section">
    <img [src]="compatibilityFilesModal[compatibilityModal].imgUrl" alt="not_xlsx">
  </div>
  <div [class]="'text-content ' + 'm' + compatibilityModal">
    <h1 class="col-12 mb-3">{{compatibilityFilesModal[compatibilityModal].title}}</h1>
    <h4 *ngIf="compatibilityFilesModal[compatibilityModal].subtitle"> {{compatibilityFilesModal[compatibilityModal].subtitle}}</h4>
  </div>
  <div class="button-section">
    <button (click)="close(true)">{{ 'modal.button.continue' | translate }} </button>
  </div>
</div>
<!-- Compatibility notices end -->

<!-- Dont Saved -->
<div class="dontSave-modal" *ngIf="typeModal == 'dontSave'" #dontSave>
  <div class="img-section m-auto">
    <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/checklist.png" alt="checklist">
  </div>
  <div class="text-content m-auto">
    <h3>{{ 'modal-dontSave.text' | translate }}</h3>
  </div>
  <div class="button-section m-auto">
    <button (click)="close(false)">{{ 'modal.button.cancel' | translate }}</button>
    <button class="outline" (click)="close(true)">{{ 'modal.button.accept' | translate}}</button>
  </div>
</div>
<!-- Dont Saved -->

<!-- Reminder -->
<div class="reminder-modal" *ngIf="typeModal == 'reminder'" #reminder>
  <div class="img-section m-auto">
    <img src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/reminder.png" alt="reminder">
  </div>
  <div class="text-content m-auto">
    <h4>{{ 'modal-reminder.text' | translate }}</h4>
  </div>
  <div class="button-section m-auto">
    <button class="outline" (click)="close(false)">{{ 'modal.button.cancel' | translate }}</button>
    <button (click)="close(true)">{{ 'modal.button.send' | translate}}</button>
  </div>
</div>
<!-- Reminder -->
