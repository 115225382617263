<ng-template #content let-c="close" let-d="dismiss">
  <form (ngSubmit)="onSubmit()" class="modal-profile">
    <div class="custom--modal-form">
      <div class="modal-header d-flex align-items-center pb-2">
        <h4 class="modal-title">{{ 'header.profile.edit' | translate }}</h4>
        <button type="button" class="close px-0" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-0 pb-0">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <div class="content-profile-img">
              <img src="{{ image }}" width="100%" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="font-weight-bold">{{ 'modal.name' | translate }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'modal.placeholder.name' | translate }}"
                    [formControl]="form.controls['name']"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mt-3 d-flex" style="position: relative;flex-wrap: wrap;">
                  <label class="font-weight-bold col-12">{{ 'modal.phone' | translate }}</label>
                  <ngx-intl-tel-input [cssClass]="'form-control col-12'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="false"
                    customPlaceholder="#######"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="countryISO"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    name="phone"
                    [formControl]="form.controls['phone']">
                  </ngx-intl-tel-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ 'modal.uploadPic' | translate }}</label>
                  <div class="input-group input-group--custom">
                    <i class="ti-na remove-profile-img" (click)="removeImage()" *ngIf="selected"></i>
                    <input
                      type="file"
                      id="profile-picture"
                      style="display: none;"
                      (change)="changePicture($event)"
                      accept="image/*"
                      class="form-control only-label"
                      placeholder="Seleccionar"
                    />
                    <label for="profile-picture" class="form-control form-control-lg along rounded text-black-50">{{ labelText | translate }}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bold">{{ 'modal.email' | translate }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'modal.placeholder.email' | translate }}"
                    [formControl]="form.controls['email']"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex align-items-center justify-content-center">
        <button type="submit" class="btn btn-warning w-25 rounded" [disabled]="loading">{{ 'modal.button.save' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>
